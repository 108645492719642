import api from "config";

export async function requestFetch({ path, value }) {
  return await api
    .post(path, value)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}
