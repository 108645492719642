import React from "react";
import { Form, Button, AutoCenter, Stepper } from "antd-mobile";
import Swal from "sweetalert2";
import { PopupModal } from "../../ModalComponent/ModalPopup/PopupModal";
import { FillinOutline } from "antd-mobile-icons";
import { requestFetch } from "Fetch/requestFetch";

export const OrderChange = ({ id, products, GetOrders, dukon_name }) => {
  const ReturnProduct = (value) => {
    Swal.fire({
      title: "Вы уверены!",
      icon: "warning",
      text: ``,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "ДА",
      denyButtonText: `Нет`,
    }).then((result) => {
      if (result.isConfirmed) {
        requestFetch({
          path: "agent-item-update",
          value: {
            item_id: [value],
            order_id: id,
          },
        })
          .then(function (res) {
            console.log(res);
            if (res.status === 200) {
              Swal.fire({
                title: `Успешно изменён!`,
                icon: "success",
                confirmButtonText: "OK",
              });
              GetOrders();
            } else {
              Swal.fire({
                title: "Что-то пошло не так!!!",
                icon: "warning",
                confirmButtonText: "OK",
              });
              GetOrders();
            }
          })
          .catch(function (error) {
            // console.log(error);
            Swal.fire({
              title: "Что-то пошло не так!!!",
              icon: "warning",
              button: "Ок",
            });
          });
      } else if (result.isDenied) {
        Swal.fire("Хорошо как пожелаете", "", "info");
      }
    });
  };

  return (
    <PopupModal name={<FillinOutline style={{ fontSize: 18 }} />}>
      <AutoCenter>
        <h2 style={{ color: "#ff3141", marginBottom: 10 }}> {dukon_name}</h2>
        <p style={{ color: "#ff3141", marginBottom: 10 }}>
          окно для изминения количество заказа
        </p>
      </AutoCenter>
      <div
        style={{ height: "100vh", overflowY: "scroll", padding: "0 0 50px 0" }}
      >
        <Form
          layout="vertical"
          onFinish={ReturnProduct}
          footer={
            <Button block type="submit" color="primary">
              Изменить
            </Button>
          }
          style={{ paddingBottom: 30 }}
        >
          {products.map((item) => (
            <Form.Item
              key={item.id}
              initialValue={Number(item.soni)}
              rules={[
                {
                  min: 0,
                  type: "number",
                  message: `Вы ввели отрицательное число!!!!`,
                },
              ]}
              name={item.id}
              label={item.nomi}
              className="oreders-change"
            >
              <Stepper
                style={{
                  "--border": "1px solid #f5f5f5",
                  "--border-inner": "none",
                  "--height": "36px",
                  "--input-width": "70px",
                  "--input-background-color": "var(--adm-color-background)",
                  "--active-border": "1px solid #1677ff",
                  "--input-font-size": "18px",
                }}
              />
            </Form.Item>
          ))}
        </Form>
      </div>
    </PopupModal>
  );
};
