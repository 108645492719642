import axios from "axios";
import { http } from "./configs";

const api = axios.create({
  baseURL: http.BASE_URL,
  responseType: "json",
});

api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("_auth")}`;
  return config;
});

export default api;
