import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { PopupModal } from "../ModalComponent/ModalPopup/PopupModal";
import { AutoCenter, NumberKeyboard, VirtualInput } from "antd-mobile";
import Swal from "sweetalert2";
import { useAvtorization } from "util/ZustandOrdersAdd/avtorization/useAvtorization";
import { numberFormat } from "util/numberFormat";
import styles from "../Orders/Orders.module.css";
import { requestFetch } from "Fetch/requestFetch";

export const DeliveryPayment = ({
  total_skidka,
  id,
  GetOrders,
  eltuvchi_id,
}) => {
  const { token } = useAvtorization();
  const [nalichni, setNalichni] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  let Dolg = total_skidka - nalichni;

  const AddPayment = (value) => {
    setButtonDisabled(true);
    value.id = id;
    value.eltuvchi_id = eltuvchi_id;
    value.order_id = id;
    value.token = token;
    value.qarz = Dolg;
    if (Dolg < 0) {
      setButtonDisabled(false);
      Swal.fire({
        title: `Это что!? ${numberFormat(value.qarz)}`,
        text: "Не шути так считай правельно",
        icon: "warning",
        confirmButtonText: "Ок",
      });
      return null;
    }
    Swal.fire({
      title: "Проверьте данные!",
      icon: "warning",
      text: `Долг: ${numberFormat(value.qarz) ?? 0} ,  Наличка: ${
        numberFormat(value.naqd) ?? 0
      }!`,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "ДА",
      denyButtonText: `Нет`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        requestFetch({ path: "eltuvchi-done-order", value: value })
          .then(function (res) {
            if (res.data.error === 0) {
              setButtonDisabled(false);
              Swal.fire({
                title: `Оплата принята!`,
                icon: "success",
                confirmButtonText: "Спасибо",
              });
            } else {
              setButtonDisabled(false);
              Swal.fire({
                title: "Что-то пошло не так!!!",
                icon: "warning",
                confirmButtonText: "Ок",
              });
            }
            // console.log(res);
            GetOrders();
          })
          .catch(function (error) {
            setButtonDisabled(false);
            console.log(error);
            Swal.fire({
              title: "Что-то пошло не так!!!",
              icon: "warning",
              confirmButtonText: "Ок",
            });
            GetOrders();
          });
      } else if (result.isDenied) {
        setButtonDisabled(false);
        Swal.fire("Хорошо как пожелаете", "", "info");
      }
    });
  };

  return (
    <PopupModal name={"Оплата"}>
      <AutoCenter>
        <h2 style={{ paddingTop: 10, color: "#ff4d4f" }}>Форма оплаты</h2>
        <p style={{ paddingTop: 10 }}>
          Сумма заказа: {numberFormat(total_skidka)}
        </p>
      </AutoCenter>
      <div style={{ width: "100vw" }}>
        <Form layout="vertical" onFinish={AddPayment}>
          <Form.Item label="Долг">
            <Input
              value={Dolg}
              disabled
              style={{ color: "red", fontSize: 18 }}
            />
          </Form.Item>
          <Form.Item label="Оплата наличными" name={"naqd"} initialValue="0">
            {/*<InputNumber*/}
            {/*  style={{ minWidth: "50vw" }}*/}
            {/*  onChange={(e) => setNalichni(e)}*/}
            {/*  value={nalichni}*/}
            {/*/>*/}
            <VirtualInput
              keyboard={<NumberKeyboard />}
              className={styles.ClientAddInp}
              onChange={(e) => setNalichni(e)}
              value={nalichni}
            />
          </Form.Item>
          {/* <Form.Item label="Оплата на Карту" name={"karta"}>
            <InputNumber
              style={{ minWidth: "50vw" }}
              onChange={(e) => setKarta(e)}
              value={karta}
            />
          </Form.Item>
          <Form.Item label="Оплата  перечислением" name={"plastik"}>
            <InputNumber
              style={{ minWidth: "50vw" }}
              onChange={(e) => setPerechisleniye(e)}
              value={perechisleniye}
            />
          </Form.Item> */}
          <Form.Item>
            <Button
              disabled={buttonDisabled}
              type="primary"
              htmlType="submit"
              danger
              style={{ width: "100%", marginTop: 15, marginBottom: 15 }}
            >
              Принять
            </Button>
          </Form.Item>
        </Form>
      </div>
    </PopupModal>
  );
};
