import React from "react";
import { Button } from "antd-mobile";
import styles from "./Orders.module.css";
export const OrdersСonfirmDelete = (props) => {
  return (
    <div className={styles.OrdersNewBlockBtn}>
      <Button
        color="success"
        size="mini"
        onClick={(id) => props.ConfirmOrders(id)}
      >
        Подтвердить заказ
      </Button>
      <Button color="danger" size="mini" onClick={props.DeleteOrders}>
        Отменить заказ
      </Button>
    </div>
  );
};
// DeleteOrders
