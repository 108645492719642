import React from "react";
import css from "./StyleOrders.module.css";
export const OrdersReportSerch = (props) => {
  return (
    <input
      size="mini"
      onChange={({ target: { value } }) => props.Search(value)}
      placeholder="Список должников Поиск..."
      className={css.searchInp}
    />
  );
};
