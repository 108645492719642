import React, { useEffect, useRef } from "react";
import { Form } from "antd";
import { useOpders } from "util/ZustandOrdersAdd/OrderStore";
import FormAddItem from "./FormAddItem";

export default function OrderItemUpdate({ item, setVisible }) {
  const updateItem = useOpders((state) => state.updateItem);
  const [form] = Form.useForm();
  const inpRef = useRef();

  const handleUpdate = (value) => {
    const items = {
      product_id: item.product_id,
      soni: value.soni,
    };
    updateItem(items);
    setVisible(false);
    form.resetFields();
  };
  useEffect(() => {
    inpRef.current.focus();
  }, []);
  return <FormAddItem onFinish={handleUpdate} form={form} inpRef={inpRef} />;
}
