import React from "react";
import styles from "../Orders/Orders.module.css";

export const DeliverypriceMap = ({ product }) => {
  return product.map((i, index) => (
    <tr key={i.id} className={styles.PriceDataTr}>
      <td className={styles.PriceDataTd}>{index + 1}</td>
      <td className={styles.PriceDataTd}>{i.nomi}</td>
      <td className={styles.PriceDataTd}>{i.soni}</td>
      {/* <td className={styles.PriceDataTd}>{i.price}</td>
      <td className={styles.PriceDataTd}>{i.price * i.soni}</td> */}
    </tr>
  ));
};
