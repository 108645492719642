import React, { useState } from "react";
import { ModalTop } from "../../ModalComponent/ModalTop/ModalTop";
import {
  Form,
  TextArea,
  Button,
  VirtualInput,
  NumberKeyboard,
} from "antd-mobile";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import style from "../Orders.module.css";
import { numberFormat } from "util/numberFormat";
import { useUpdateOpders } from "util/ZustandOrdersAdd/OrdersAddNewItem/OrdersAddNewItemState";
import { requestFetch } from "Fetch/requestFetch";

export const OrdersItemPost = ({ id, GetOrders }) => {
  const { orderItem, deleteOrder } = useUpdateOpders();
  const [isdisabled, setDisabled] = useState(false);
  const [num, setNum] = useState("");
  const [form] = Form.useForm();
  const history = useNavigate();

  // отправляем заказаз в админку
  const GetNewOrders = (value) => {
    setDisabled(true);
    //   выводим сумму заказа
    //  let TotalCash = 0;
    let Price = 0;
    let Soni = 0;
    let Summa = 0;
    if (orderItem.length === 0) {
      Swal.fire({
        title: `Oops...`,
        text: "Не шутите так))) с начало оформите заказный лист!!!!!",
        icon: "warning",
        button: "Спасибо",
      });
      return null;
    }
    orderItem.forEach(({ price, soni }) => {
      Price = Number(price);
      Soni = Number(soni);
      Summa = Price * Soni;
      TotalCash += Summa;
    });

    const isObjectData = Object.assign({}, orderItem);
    value.products = isObjectData;
    value.order_id = id;

    Swal.fire({
      title: "Оформить заказ!?",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Сахранить",
      denyButtonText: `Отмена`,
    }).then((result) => {
      if (result.isConfirmed) {
        requestFetch({ path: "agent-order-add", value: value })
          .then(function (response) {
            // console.log(response);
            if (response.data.error === 0) {
              setDisabled(false);
              Swal.fire({
                title: `Отлично`,
                text: "Товар добавлен!",
                icon: "success",
                confirmButtonText: "Спасибо",
              });
              form.resetFields();
              deleteOrder();
              GetOrders();
              history("/orders");
            } else {
              setDisabled(false);
              Swal.fire({
                title: `O__o`,
                text: `${response.data.xabar}`,
                icon: "warning",
              });
              GetOrders();
            }
          })
          .catch(function (error) {
            Swal.fire("Что-то пошло не так!!", "", "info");
            GetOrders();
            setDisabled(false);
          });
      } else if (result.isDenied) {
        Swal.fire("Хорошо как пожелаете", "", "info");
        GetOrders();
        setDisabled(false);
      }
    });
  };

  let TotalCash = 0;
  let Cash = 0;
  let Soni = 0;
  let Summa = 0;
  let Skidka = 0;
  let TotalSkidka = 0;

  if (orderItem) {
    orderItem.forEach(({ price, soni }) => {
      Cash = Number(price);
      Soni = Number(soni);
      Summa = Cash * Soni;
      TotalCash += Summa;
      Skidka = (TotalCash * num) / 100;
      TotalSkidka = TotalCash - Skidka;
    });
  }

  return (
    <ModalTop name="Оформить заказ">
      <div style={{ paddingTop: 10 }}>
        <h4 style={{ paddingLeft: 13 }}>Дополнительные данные заказа</h4>
        <Form onFinish={GetNewOrders} form={form}>
          <Form.Item name="chegirma" label="Скидка в процентах">
            <VirtualInput
              value={num}
              keyboard={<NumberKeyboard />}
              className={style.ClientAddInp}
              onChange={(e) => setNum(e)}
            />
          </Form.Item>

          <Form.Item name="izoh" label="Коментарии к заказу">
            <TextArea
              className={style.ClientAddInp}
              maxLength={100}
              rows={2}
              showCount
            />
          </Form.Item>

          <Form.Item>
            <Button
              disabled={isdisabled}
              type="submit"
              block
              color="danger"
              size="large"
              style={{
                marginTop: 5,
                marginBottom: 10,
              }}
            >
              Сахранить заказ
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div>
        <table style={{ width: "70%", paddingTop: 15 }}>
          <tbody>
            <tr>
              <td style={{ paddingLeft: 15 }}>Сумма заказа</td>
              <td style={{ fontSize: 18 }}>{numberFormat(TotalCash)}</td>
            </tr>
            <tr>
              <td style={{ paddingLeft: 15 }}>Сумма к оплате:</td>
              <td style={{ fontSize: 18 }}>{numberFormat(TotalSkidka)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </ModalTop>
  );
};
