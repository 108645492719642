import React from "react";
import { Collapse, Tag } from "antd-mobile";
import { OrdersTransfer } from "./OrdersTransfer";
import { numberFormat } from "util/numberFormat";

export const OrderConfiredList = ({ filtered }) => {
  const dataConfirm = filtered.map((i, index) => (
    <Collapse.Panel
      key={i.id}
      title={
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>{index + 1}</td>
              <td>{i.dukon_name}</td>
              <td style={{ width: "20%" }}>
                {i.status === "confirmed" ? (
                  <Tag color="#87d068">Заказ подтверждён</Tag>
                ) : i.status === "berildi" ? (
                  <Tag color="#36cfc9">Заказ передан доставщику</Tag>
                ) : i.status === "eltuvchi" ? (
                  <Tag color="#ff7875">Заказ у доставщика</Tag>
                ) : i.status === "done" ? (
                  <Tag color="#d48806">Заказ передан заказчику</Tag>
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>
      }
    >
      <ul style={{ paddingLeft: 10 }}>
        <li>Сумма заказ: {numberFormat(i.summa)}</li>
        <li>Скидка: {i.chegirma}</li>
        <li>Сумма со скидкой: {numberFormat(i.total_skidka)}</li>
        <li>Агент: {i.agent_name}</li>
        <li>Доставщик: {i.eltuvchi}</li>
      </ul>
      {!i.eltuvchi ? <OrdersTransfer id={i.id} /> : null}
    </Collapse.Panel>
  ));

  return <Collapse accordion>{dataConfirm}</Collapse>;
};
