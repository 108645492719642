import React, { useEffect, useState, useContext } from "react";
import Swal from "sweetalert2";
import { Popover } from "antd";
import { AutoCenter } from "antd-mobile";
import { Button, Form, Select } from "antd";
import { OrdersProvider } from "../../context/OrdersProvider";
import { requestFetch } from "Fetch/requestFetch";

export const OrdersTransfer = ({ id }) => {
  const [users, setUsers] = useState([]);
  const GetOrders = useContext(OrdersProvider);

  // Запрос для имён рабочих
  const FetchUserData = () => {
    requestFetch({ path: "get-users" })
      .then(function (res) {
        if (res.status === 200) {
          setUsers(res.data);
          //  console.log("WorkerList", res.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    FetchUserData();
    // eslint-disable-next-line
  }, []);

  // передаём заказ даставщику
  const OrdersTransfer = (value) => {
    value.order_id = id;
    Swal.fire({
      title: "Вы уверены!",
      icon: "warning",
      text: ``,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "ДА",
      denyButtonText: `Нет`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        requestFetch({ path: "add-eltuvchi-order", value })
          .then(function (res) {
            if (res.data.error === 0) {
              Swal.fire({
                title: `Успешно!`,
                icon: "success",
                button: "OK",
              });
              GetOrders();
            } else {
              Swal.fire({
                title: "Что-то пошло не так!!!",
                icon: "warning",
                button: "Ок",
              });
              GetOrders();
            }
            // console.log(res);
          })
          .catch(function (error) {
            // console.log(error);
            Swal.fire({
              title: "Что-то пошло не так!!!",
              icon: "warning",
              button: "Ок",
            });
          });
      } else if (result.isDenied) {
        Swal.fire("Хорошо как пожелаете", "", "info");
      }
    });
  };
  // выводим имена агентов
  const dostavka = users.map((i) =>
    i.rol === "dostavka" ? (
      <Select.Option key={i.id} value={i.id}>
        {i.fio}
      </Select.Option>
    ) : null
  );
  const text = (
    <AutoCenter>
      <span>Форма передачи заказа</span>
    </AutoCenter>
  );
  const content = (
    <div>
      <Form onFinish={OrdersTransfer} layout="vertical">
        <Form.Item
          name="eltuvchi_id"
          rules={[
            {
              required: true,
              message: "Выберите доставщика",
            },
          ]}
        >
          <Select placeholder="Выберите доставщика">{dostavka}</Select>
        </Form.Item>
        <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="primary"
            size="mini"
            htmlType="submit"
            style={{ marginTop: 10 }}
          >
            Ok
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
  return (
    <Popover placement="top" title={text} content={content} trigger="click">
      <div style={{ float: "right" }}>
        <Button type="primary">Перадь доставщикам</Button>
      </div>
    </Popover>
  );
};
