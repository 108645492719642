import React from "react";
import SkeletonKopy from "../Loading/SkeletonKopy";
import Swal from "sweetalert2";
import { DeliveryDetailes } from "./DeliveryDetailes";
import { Card, Button } from "antd-mobile";
import { FcShop } from "react-icons/fc";
import { numberFormat } from "util/numberFormat";
import { requestFetch } from "Fetch/requestFetch";
import { timestempConvert } from "util/tymestempConvector";
import styles from "../Orders/Orders.module.css";
import css from "./Delivery.module.css";
import StatusOrder from "./custom/StatusOrder";
import DeliveryBroadcast from "./DeliveryBroadcast";
import { useState } from "react";
import { useEffect } from "react";
import Refresh from "components/custom/Refresh";

export const DeliveryList = ({ orders, GetOrders, isLoding }) => {
  const [user, setUser] = useState([]);
  useEffect(() => {
    requestFetch({ path: "get-users" })
      .then(function (res) {
        if (res.status === 200) {
          setUser(res.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  // принимаем заказ от админа
  const ConfirmOrders = (key) => {
    Swal.fire({
      title: "Вы уверены?",
      text: "Принять заказ!",
      showDenyButton: true, // showCancelButton: true,
      confirmButtonText: "ДА",
      denyButtonText: `Нет`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        requestFetch({ path: "order-confirm-deliver", value: { id: key } })
          .then(function (res) {
            Swal.fire({
              title: `Заказ принят!`,
              icon: "success",
              confirmButtonText: "Спасибо",
            }); //   console.log(res.data);
            GetOrders();
          })
          .catch(function (error) {
            //   console.log(error);
            Swal.fire({
              title: "Что-то пошло не так!!!",
              icon: "warning",
              confirmButtonText: "Ок",
            });
            GetOrders();
          });
      } else if (result.isDenied) {
        Swal.fire("Хорошо как пожелаете", "", "info");
      }
    });
  };

  // выводим заказный лист
  // eslint-disable-next-line array-callback-return
  const OrdersCard = orders?.map((i) => {
    return (
      <Card
        key={i.id}
        style={{
          boxShadow: "0px 0px 7px 0px #bcbcbc",
          borderRadius: "16px",
          padding: 5,
          margin: "15px 20px",
        }}
        title={
          <div>
            <div style={{ fontWeight: 900, paddingLeft: 10, fontSize: 16 }}>
              <FcShop style={{ marginRight: "4px" }} />
              <span> {i.d_name}</span>
            </div>
            <StatusOrder status={i.status} />
          </div>
        }
      >
        <div className={styles.content}>
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td>Дата заказа:</td>
                <td>{timestempConvert(i.sana)}</td>
              </tr>
              <tr>
                <td>Сумма заказа:</td>
                <td>{numberFormat(i.summa)}</td>
              </tr>
              <tr>
                <td>Скидка:</td>
                <td>{numberFormat(i.chegirma)}</td>
              </tr>
              <tr>
                <td>Сумма к оплате:</td>
                <td>{numberFormat(i.total_skidka)}</td>
              </tr>
              <tr>
                <td>Доставщик:</td>
                <td>{i.eltuvchi}</td>
              </tr>
              <tr>
                <td>Агент:</td>
                <td>{i.agent_name}</td>
              </tr>
              <tr>
                <td>Адрес:</td>
                <td>{i.manzil}</td>
              </tr>
              <tr>
                <td>Телефон:</td>
                <td>{i.telefon}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={css.blockFooter} onClick={(e) => e.stopPropagation()}>
          {i.status === "berildi" ? (
            <Button
              color="success"
              size="mini"
              onClick={() => ConfirmOrders(i.id)}
            >
              Принять
            </Button>
          ) : null}
          <DeliveryDetailes
            id={i.id}
            dukon_name={i.dukon_name}
            total_skidka={i.total_skidka}
            izoh={i.izoh}
            products={i.products}
            eltuvchi_id={i.eltuvchi_id}
            d_balans={i.d_balans}
            GetOrders={GetOrders}
            status={i.status}
          />
          {i.status === "berildi" ? (
            <DeliveryBroadcast
              GetOrders={GetOrders}
              order_id={i.id}
              user={user}
            />
          ) : null}
        </div>
      </Card>
    );
  });

  if (isLoding) {
    return <SkeletonKopy />;
  }

  return (
    <Refresh data={orders} fnc={GetOrders}>
      {OrdersCard}
    </Refresh>
  );
};
