import React, { useState } from "react";
import { Form, Input, Button, AutoCenter } from "antd-mobile";
import styles from "./Login.module.css";
import { EyeInvisibleOutline, EyeOutline } from "antd-mobile-icons";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Preloader from "../Loading/Preloader";
// import dateFormat from "dateformat";
// import { useCallback } from "react";
import { useAuth } from "util/ZustandOrdersAdd/avtorization/userAvtorization";
import { requestFetch } from "Fetch/requestFetch";

export const Login = () => {
  const { authUser } = useAuth();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const history = useNavigate();

  const onFinish = (values) => {
    setLoading(true);
    requestFetch({ path: "login", value: values })
      .then(function (response) {
        if (response.status === 200) {
          localStorage.setItem("_auth", response.data.access_token);
          authUser({
            ...response.data.user,
            token: response.data.access_token,
          });
          if (response.data.user.rol === "agent") return history("/product");
          if (response.data.user.rol === "dostavka")
            return history("/delivery");
          if (response.data.user.rol === "admin" || "manager")
            return history("/orders");
          Swal.fire({
            title: `Добро пожаловать  ${response.data.fio}`,
            icon: "success",
            confirmButtonText: "Спасибо",
          });
        } else if (response.status !== 200) {
          Swal.fire({
            title: "Ошибка!!!",
            text: "проверьте логин и пароль",
            icon: "warning",
            confirmButtonText: "Ок",
          });
        } else {
          Swal.fire({
            title: "Авторизуйтесь!!!",
            icon: "warning",
            confirmButtonText: "Ок",
          });
        }
      })
      .catch(function (error) {
        if (error.code === "ERR_NETWORK") {
          Swal.fire({
            title: `Ошибка соединения!!!`,
            icon: "warning",
            confirmButtonText: "Спасибо",
          });
        }
        localStorage.removeItem("DataToken");
        history("/login");

        console.log("er", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading !== false) {
    return <Preloader />;
  }

  return (
    <div className={styles.blocklogin}>
      <div>
        <AutoCenter>
          <h1 style={{ color: "#00b578" }}>Авторизация</h1>
        </AutoCenter>
        <Form
          autoComplete="off"
          onFinish={onFinish}
          layout="vertical"
          footer={
            <Button block type="submit" color="success">
              Отправить
            </Button>
          }
        >
          <Form.Item
            name="login"
            rules={[{ required: true, message: "Введите логин" }]}
          >
            <Input onChange={console.log} placeholder="Логин" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Введите пароль" }]}
          >
            <div className={styles.password}>
              <Input
                className={styles.input}
                placeholder="Пароль"
                type={visible ? "text" : "password"}
              />
              <div className={styles.eye}>
                {!visible ? (
                  <EyeInvisibleOutline onClick={() => setVisible(true)} />
                ) : (
                  <EyeOutline onClick={() => setVisible(false)} />
                )}
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
