import { FloatingBubble } from "antd-mobile";
import React, { useEffect } from "react";
import { useCategory } from "util/Category/useCategory";
import useProductsHook from "util/Product/useProductsHook";
import ProductCategory from "./OrdersCategory";
import OrdersProductCard from "./OrdersProductCard";
import { useState } from "react";
import { useOpders } from "util/ZustandOrdersAdd/OrderStore";
import { NavLink } from "react-router-dom";
import css from "./CatalogStyle.module.css";

export const OrdersPage = () => {
  const orderItem = useOpders((state) => state.order);
  const { category, loading } = useCategory();
  const { productLoad, product } = useProductsHook();
  const [isproduct, setIsProduct] = useState([]);
  useEffect(
    (_) => {
      setIsProduct(product);
    },
    [product]
  );
  // функцыя для сорта по категориям
  const handleCategory = (id) => {
    if (id !== "0") {
      const newSort = product.filter((i) => i.category_id === id);
      return setIsProduct(newSort);
    }
    return setIsProduct(product);
  };

  return (
    <div className={css.ProductWrapper}>
      <ProductCategory
        category={category}
        handleCategory={handleCategory}
        loading={loading}
      />
      <OrdersProductCard product={isproduct} productLoad={productLoad} />
      {orderItem.length !== 0 ? (
        <FloatingBubble
          axis="xy"
          magnetic="x"
          style={{
            "--initial-position-bottom": "80px",
            "--initial-position-right": "24px",
            "--edge-distance": "24px",
            "--background": "#1677ff",
          }}
        >
          <NavLink to={"/product/order"}>
            {/* <AiOutlineOrderedList fontSize={32} /> */}
            <p className={css.FloaatButtonText}>{orderItem.length}</p>
          </NavLink>
        </FloatingBubble>
      ) : null}
    </div>
  );
};
