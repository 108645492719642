import React, { useState } from "react";
import { Toast } from "antd-mobile";
import { CategoryContext } from "./CategoryContext";
import { requestFetch } from "Fetch/requestFetch";

export default function CategoryState({ children }) {
  const [category, setCategory] = useState([]);
  const [loading, setLoadeng] = useState(false);
  // получаем список категории
  const GetCategory = async () => {
    setLoadeng(true);
    await requestFetch({ path: "category-get" })
      .then(function (res) {
        if (res.status === 200) {
          setCategory(res.data);
          setLoadeng(false);
        } else
          Toast.show({
            icon: "fail",
            content: "Список продукции не загрузилось",
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <CategoryContext.Provider value={{ category, GetCategory, loading }}>
      {children}
    </CategoryContext.Provider>
  );
}
