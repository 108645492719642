import React, { useState } from "react";
import { Image, Card, Toast } from "antd-mobile";
import css from "./CatalogStyle.module.css";
import { numberFormat } from "util/numberFormat";
import AddModal from "./AddModal";
import OrderItemAddForm from "./OrderItemAdd";
import Preloader from "components/Loading/Preloader";
import { useAvtorization } from "util/ZustandOrdersAdd/avtorization/useAvtorization";
const url = process.env.REACT_APP_DB_URL;

export default function OrdersProductCard({ product, productLoad }) {
  const { rol } = useAvtorization();
  const [visible, setVisible] = useState(false);
  const [item, setItem] = useState([]);

  const onBodyClick = (e) => {
    if (rol === "dostavka") {
      Toast.show({
        duration: 3000,
        position: "top",
        icon: "fail",
        content: "Ooopss",
      });
      return null;
    }
    setVisible(true);
    setItem(e);
  };
  //   console.log(product);
  const Product = product?.map((i) => (
    <Card
      key={i.id}
      style={{
        borderRadius: "16px",
        margin: "10px 0",
        boxShadow: "0px 0px 10px 0px azure",
      }}
      className={css.ProductCard}
      onBodyClick={() => onBodyClick(i)}
    >
      <div>
        {i.img === "" ? (
          <Image src="/404" width={"100%"} height={100} />
        ) : (
          <Image lazy src={`${url + i.img}`} />
        )}
      </div>
      <div>
        <table className={css.CardTable}>
          <tbody>
            <tr>
              <td className={css.CardItemName}>Наименование</td>
              <td className={css.CardItemValue}>{i.name}</td>
            </tr>
            <tr>
              <td className={css.CardItemName}>Цена</td>
              <td className={css.CardItemValue}>{numberFormat(i.price)}</td>
            </tr>
            <tr>
              <td className={css.CardItemName}>В складе:</td>
              <td className={css.CardItemValue}> {i.soni}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Card>
  ));
  if (productLoad) return <Preloader />;
  return (
    <>
      {Product}
      <AddModal visible={visible} setVisible={setVisible}>
        <OrderItemAddForm item={item} setVisible={setVisible} />
      </AddModal>
    </>
  );
}
