// import { Spin } from "antd";
import React from "react";
import { Skeleton } from "antd-mobile";
import "./loding.css";
const Loding = () => {
  return (
    <>
      <div className="block__skeleton">
        <div className="block__skeleton-header">
          <Skeleton lineCount={15} animated className="skeleton-header-item" />
          <Skeleton lineCount={15} animated className="skeleton-header-item" />
          <Skeleton lineCount={15} animated className="skeleton-header-item" />
        </div>
        <div className="block__skeleton-content">
          <Skeleton lineCount={15} animated className="skeleton-content-item" />
          <Skeleton lineCount={15} animated className="skeleton-content-item" />
          <Skeleton lineCount={15} animated className="skeleton-content-item" />
          <Skeleton lineCount={15} animated className="skeleton-content-item" />
          <Skeleton lineCount={15} animated className="skeleton-content-item" />
          <Skeleton lineCount={15} animated className="skeleton-content-item" />
          <Skeleton lineCount={15} animated className="skeleton-content-item" />
          <Skeleton lineCount={15} animated className="skeleton-content-item" />
        </div>
        <div className="block__skeleton-footer">
          <Skeleton lineCount={15} animated className="skeleton-footer-items" />
          <Skeleton lineCount={15} animated className="skeleton-footer-items" />
        </div>
      </div>
      <div className="block__skeleton">
        <div className="block__skeleton-header">
          <Skeleton lineCount={15} animated className="skeleton-header-item" />
          <Skeleton lineCount={15} animated className="skeleton-header-item" />
          <Skeleton lineCount={15} animated className="skeleton-header-item" />
        </div>
        <div className="block__skeleton-content">
          <Skeleton lineCount={15} animated className="skeleton-content-item" />
          <Skeleton lineCount={15} animated className="skeleton-content-item" />
          <Skeleton lineCount={15} animated className="skeleton-content-item" />
          <Skeleton lineCount={15} animated className="skeleton-content-item" />
          <Skeleton lineCount={15} animated className="skeleton-content-item" />
          <Skeleton lineCount={15} animated className="skeleton-content-item" />
          <Skeleton lineCount={15} animated className="skeleton-content-item" />
          <Skeleton lineCount={15} animated className="skeleton-content-item" />
        </div>
        <div className="block__skeleton-footer">
          <Skeleton lineCount={15} animated className="skeleton-footer-items" />
          <Skeleton lineCount={15} animated className="skeleton-footer-items" />
        </div>
      </div>
    </>
  );
};

export default Loding;
