import React, { useState } from "react";
import { DotLoading, AutoCenter, Button, Space } from "antd-mobile";
import { Typography } from "antd";
import { DeleteOutline } from "antd-mobile-icons";
import style from "../Orders.module.css";
import { numberFormat } from "util/numberFormat";
import { useOpders } from "util/ZustandOrdersAdd/OrderStore";
import AddModal from "./AddModal";
import OrderItemUpdate from "./OrderItemUpdate";
import { RxUpdate } from "react-icons/rx";
import { List } from "antd-mobile";
const { Text } = Typography;

export const OrdersTable = () => {
  const [visible, setVisible] = useState(false);
  const [item, setItem] = useState([]);
  const { order, removeItem } = useOpders();
  // выводим данные заказа в талицу
  // eslint-disable-next-line
  const Dates = order.map((e) => (
    <List.Item
      key={e.product_id}
      description={`каличество:  ${e.soni}  цена: ${numberFormat(
        e.price
      )} сумма: ${numberFormat(e.price * e.soni)}`}
      extra={
        <Space direction="vertical">
          <Button onClick={() => itemChange(e)} size="mini" color="warning">
            <RxUpdate style={{ color: "#fff" }} />
          </Button>
          <Button
            onClick={() => removeItem(e.product_id)}
            size="mini"
            color="danger"
          >
            <DeleteOutline />
          </Button>
        </Space>
      }
    >
      {e.name}
    </List.Item>
  ));

  // изменяем каличество
  const itemChange = (value) => {
    setVisible(true);
    setItem(value);
  };

  // Loading
  if (order === 0) {
    return (
      <div className={style.BlockPriceLoading}>
        <DotLoading color="currentColor" />
      </div>
    );
  }

  return (
    <section style={{ padding: "10px 0 10px 0" }}>
      <AutoCenter>
        <Text>Прайс лист</Text>
      </AutoCenter>
      <List>{Dates}</List>
      <AddModal visible={visible} setVisible={setVisible}>
        <OrderItemUpdate item={item} setVisible={setVisible} />
      </AddModal>
    </section>
  );
};
