import React from "react";
import { useEffect } from "react";
import useBalanseGoods from "util/BalansGoods/useBalanseGoods";
import css from "./Delivery.module.css";
import { Button } from "antd-mobile";
import {} from "antd-mobile/es/utils/sleep";
import Refresh from "components/custom/Refresh";
import Loding from "components/Loading/SkeletonKopy";
import ResultWarning from "components/Loading/result/ResultWarning";

export default function TransferredGoods() {
  const { onTransferredGoods, loading, transferData, onTransferConfirm } =
    useBalanseGoods();

  useEffect(() => {
    onTransferredGoods();
  }, [onTransferredGoods]);

  const dataMap = transferData.map((Item, index) => {
    return (
      <main key={index.toString()} className={css.transferredGoodsWrapper}>
        <div className={css.transferredGoodsHeader}>
          <span>Кто передал:</span>{" "}
          <span className={css.transferredGoodsHeaderTitle}>{Item.fio}</span>
        </div>

        {Item.orders.map((order) => (
          <div key={order.id}>
            <table className={css.transferredGoodsTable}>
              <caption key={index + 1} className={css.transferredGoodsCaption}>
                Клиент {order.d_name}
              </caption>
              <thead>
                <tr className={css.transferredGoodsTRowHead}>
                  <th>№</th>
                  <th>Наименование</th>
                  <th>Количество</th>
                </tr>
              </thead>
              <tbody>
                {order.products.map((product, index) => (
                  <tr
                    key={product.id}
                    className={css.transferredGoodsTBodyTRow}
                  >
                    <td className={css.transferredGoodsTdNumber}>
                      {index + 1}.
                    </td>
                    <td className={css.transferredGoodsTdName}>
                      {product.nomi}
                    </td>
                    <td className={css.transferredGoodsTd}>{product.soni}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className={css.transferredGoodsFooter}>
              <Button
                onClick={() => onTransferConfirm(order.id)}
                size="small"
                color="warning"
              >
                {" "}
                Принять
              </Button>
            </div>
          </div>
        ))}
      </main>
    );
  });

  if (loading) {
    return <Loding />;
  }
  if (transferData.length === 0) {
    return <ResultWarning />;
  }
  return (
    <Refresh data={transferData} fnc={onTransferredGoods}>
      {dataMap}
    </Refresh>
  );
}
