import React from "react";
import { useState } from "react";
import { ViloyatContext } from "./ViloyatContext";
import { requestFetch } from "Fetch/requestFetch";

export default function ViloyatState({ children }) {
  const [viloyat, setViloyat] = useState([]);
  const GetViloyat = async () => {
    await requestFetch({ path: "regions" })
      .then((res) => {
        if (res.status === 200) setViloyat(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ViloyatContext.Provider value={{ GetViloyat, viloyat }}>
      {children}
    </ViloyatContext.Provider>
  );
}
