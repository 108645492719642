import React, { useState } from "react";
import { PopupModal } from "../ModalComponent/ModalPopup/PopupModal";
import { TextArea, Button, AutoCenter } from "antd-mobile";
import { Form, Select, InputNumber } from "antd";
import Swal from "sweetalert2";
import style from "./Delivery.module.css";
import { FcDonate, FcSimCardChip, FcLibrary } from "react-icons/fc";
import { useAvtorization } from "util/ZustandOrdersAdd/avtorization/useAvtorization";
import { requestFetch } from "Fetch/requestFetch";

export const RepaymentOrder = ({ dukonlar, GetOrders }) => {
  const [form] = Form.useForm();
  const { rol } = useAvtorization();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  // принимаем оплату
  const AddPayment = (value) => {
    if (value.summa < 0 || value.karta < 0 || value.plastik < 0) {
      Swal.fire({
        title: `Ошибка `,
        text: `сумма отрицательная!!`,
        icon: "warning",
        confirmButtonText: "upss..",
      });
      return false;
    }
    setButtonDisabled(true);

    Swal.fire({
      title: "Подтверждаете оплату!?",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Да",
      denyButtonText: `Отмена`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        requestFetch({ path: "qarz-tolov", value: value })
          .then(function (response) {
            // console.log(response);
            if (response.data.error === 0) {
              setButtonDisabled(false);
              Swal.fire({
                title: `Отлично`,
                text: "Оплата  принята!",
                icon: "success",
                confirmButtonText: "Спасибо",
              });
              form.resetFields();
              GetOrders();
            }
          })
          .catch(function (error) {
            setButtonDisabled(false);
            Swal.fire("Что-то пошло не так!!", "", `${error}`);
            // console.log(error);
            GetOrders();
          });
      } else if (result.isDenied) {
        setButtonDisabled(false);
        Swal.fire("Хорошо как пожелаете", "", "info");
      }
    });
  };

  return (
    <PopupModal name="Добавить оплату">
      <AutoCenter>
        <h3 style={{ padding: "10px 0", color: "#00b578", fontSize: 20 }}>
          Форма оплаты
        </h3>
      </AutoCenter>
      <Form
        onFinish={AddPayment}
        form={form}
        autoComplete="off"
        initialValues={{
          summa: 0,
          karta: 0,
          plastik: 0,
        }}
      >
        <Form.Item
          name="dukon_id"
          label="Выберите клиента"
          rules={[{ required: true, message: "Выберите клиента" }]}
        >
          <Select
            showSearch
            style={{
              width: "100%",
            }}
            // placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={dukonlar?.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Form.Item>

        <Form.Item name="summa" label="Оплата наличными">
          <InputNumber
            prefix={<FcDonate style={{ fontSize: 20 }} />}
            style={{ minWidth: "50vw" }}
          />
        </Form.Item>
        {rol === "admin" || rol === "manager" || rol === "programist" ? (
          <>
            <Form.Item label="Оплата на Карту" name="karta">
              <InputNumber
                prefix={<FcSimCardChip style={{ fontSize: 20 }} />}
                style={{ minWidth: "50vw" }}
              />
            </Form.Item>
            <Form.Item label="Оплата  перечислением" name="plastik">
              <InputNumber
                prefix={<FcLibrary style={{ fontSize: 20 }} />}
                style={{ minWidth: "50vw" }}
              />
            </Form.Item>
          </>
        ) : null}

        <Form.Item name="izoh" label="Коментарии">
          <TextArea
            className={style.ClientAddInp}
            maxLength={100}
            rows={1}
            showCount
          />
        </Form.Item>

        <Form.Item>
          <Button
            disabled={buttonDisabled}
            type="submit"
            block
            color="danger"
            size="large"
            style={{
              marginTop: 5,
              marginBottom: 10,
            }}
          >
            Принять
          </Button>
        </Form.Item>
      </Form>
    </PopupModal>
  );
};
