import React, { useEffect, useRef } from "react";
import { Form } from "antd";
import { useOpders } from "util/ZustandOrdersAdd/OrderStore";
import FormAddItem from "./FormAddItem";
export default function OrderItemAddForm({ item, setVisible }) {
  const addOrderItem = useOpders((state) => state.addOrderItem);
  const [form] = Form.useForm();
  const inpRef = useRef();
  const addItems = (value) => {
    const items = {
      product_id: item.id,
      name: item.name,
      price: item.price,
      soni: value.soni,
    };
    addOrderItem(items);
    setVisible(false);
    inpRef.current.blur();
    form.resetFields();
  };
  useEffect(() => {
    inpRef.current.focus();
  }, [inpRef]);

  return <FormAddItem onFinish={addItems} form={form} inpRef={inpRef} />;
}
