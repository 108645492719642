import { create } from "zustand";
import { Toast } from "antd-mobile";
import { persist } from "zustand/middleware";

export const useOpders = create(
  persist(
    (set) => ({
      order: [],
      addOrderItem: (item) =>
        set((state) => {
          const ord = state.order;
          const findProduct = ord.find((p) => p.product_id === item.product_id);
          if (findProduct) {
            findProduct.soni = Number(findProduct.soni) + Number(item.soni);
   
            } else if (item.soni < 0) {         if (findProduct.soni < 0) {
              Toast.show({
                duration: 3000,
                position: "top",
                content: "Наименование удалено из Прайс листа",
              });
              findProduct.soni = 0;
              Toast.show({
                duration: 3000,
                position: "top",
                content: `${item.soni + "шт минусована"}`,
              });
            } else {
              Toast.show({
                duration: 3000,
                position: "top",
                content: `${item.soni + "\xa0шт добавлено"}`,
              });
            }
            const newItem = ord.filter((i) => i.soni !== 0);
            return { order: newItem };
          } else {
            Toast.show({ position: "top", content: "Добавлено" });
            return { order: [...state.order, item] };
          }
        }),
      updateItem: (item) =>
        set((state) => {
          const order = state.order;
          const findItem = order.find((i) => i.product_id === item.product_id);
          if (findItem) {
            findItem.soni = Number(findItem.soni) + Number(item.soni);
            if (findItem.soni < 0) {
              Toast.show({
                duration: 3000,
                position: "top",
                content: "Наименование удалено из Прайс листа",
              });
              findItem.soni = 0;
            } else if (item.soni < 0) {
              Toast.show({
                duration: 3000,
                position: "top",
                content: `${item.soni + "шт минусована"}`,
              });
            } else {
              Toast.show({
                duration: 3000,
                position: "top",
                content: `${item.soni + "\xa0шт добавлено"}`,
              });
            }
            const Item = order.filter((i) => i.soni !== 0);
            return { order: Item };
          } else {
            Toast.show({ position: "top", content: "Не успешно" });
            return { order: order };
          }
        }),
      removeItem: (key) =>
        set((state) => {
          const newOrders = state.order.filter((i) => i.product_id !== key);
          Toast.show({
            duration: 3000,
            position: "top",
            content: "Наименование удалено из Прайс листа",
          });
          return { order: newOrders };
        }),
      deleteOrder: (key) =>
        set((state) => {
          return { order: [] };
        }),
    }),
    { name: "orderItem" }
  )
);
