import React from "react";
import { OrdersFilter } from "../Orders/OrdersFilter/OrdersFilter";
import { CapsuleTabs } from "antd-mobile";
import DeliveryBalansGoodsList from "components/Delivery/DeliveryBalansGoodsList";
import TransferredGoods from "components/Delivery/TransferredGoods";
export const Home = () => {
  return (
    <CapsuleTabs>
      <CapsuleTabs.Tab title="Товары для доставки" key={1}>
        <OrdersFilter />
      </CapsuleTabs.Tab>
      <CapsuleTabs.Tab title="Остаток товара" key={2}>
        <DeliveryBalansGoodsList />
      </CapsuleTabs.Tab>
      <CapsuleTabs.Tab title="Переданные товары" key={3}>
        <TransferredGoods />
      </CapsuleTabs.Tab>
    </CapsuleTabs>
  );
};

export default Home;
