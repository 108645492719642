import React, { useState } from "react";
import {
  Form,
  TextArea,
  Button,
  //   VirtualInput,
  //   NumberKeyboard,
  Input,
} from "antd-mobile";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { numberFormat } from "util/numberFormat";
import { useOpders } from "util/ZustandOrdersAdd/OrderStore";
import { useAvtorization } from "util/ZustandOrdersAdd/avtorization/useAvtorization";
import { Select } from "antd";
import useShop from "util/shop/useShop";
import style from "../Orders.module.css";
import { requestFetch } from "Fetch/requestFetch";

export const OrdersPost = () => {
  const dataOrder = useOpders((state) => state.order);
  const deleteOrder = useOpders((state) => state.deleteOrder);
  const { dukonlar } = useShop();
  const { token } = useAvtorization();
  const [form] = Form.useForm();
  const history = useNavigate();
  const [num, setNum] = useState(0);
  const [isdisabled, setIsDisabled] = useState(false);

  // отправляем заказаз в админку
  const GetNewOrders = (value) => {
    setIsDisabled(true);
    //   выводим сумму заказа
    //  let TotalCash = 0;
    let Price = 0;
    let Soni = 0;
    let Summa = 0;
    if (dataOrder === null) {
      Swal.fire({
        title: `Oops...`,
        text: "Не шутите так))) с начало оформите заказный лист!!!!!",
        icon: "warning",
        confirmButtonText: "Спасибо",
      });
      setIsDisabled(false);
      return null;
    }
    dataOrder.forEach(({ price, soni }) => {
      Price = Number(price);
      Soni = Number(soni);
      Summa += Price * Soni;
    });

    const isObjectData = Object.assign({}, dataOrder);
    value.summa = Summa;
    value.token = token;
    value.products = isObjectData;
    console.log("value", value);
    Swal.fire({
      title: "Оформить заказ!?",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Сахранить",
      denyButtonText: `Отмена`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        requestFetch({ path: "orders-add", value: value })
          .then(function (response) {
            if (response.data.error === 0) {
              setIsDisabled(false);
              Swal.fire({
                title: `Отлично`,
                text: "Заказ принят!",
                icon: "success",
                confirmButtonText: "Спасибо",
              });
              deleteOrder();
              form.resetFields();
              history("/orders");
            } else {
              console.log("response.data", response.data);
              Swal.fire({
                title: `Ошибка`,
                text: "Ошибка при сохранение",
                icon: "error",
                confirmButtonText: "Ok",
              });
            }
          })
          .catch(function (error) {
            Swal.fire("Что-то пошло не так!!", "", "info");
          })
          .finally(() => {
            setIsDisabled(false);
          });
      } else if (result.isDenied) {
        setIsDisabled(false);
        Swal.fire("Хорошо как пожелаете", "", "info");
      }
    });
  };

  let TotalCash = 0;
  let Cash = 0;
  let Soni = 0;
  let Summa = 0;
  let Skidka = 0;
  let TotalSkidka = 0;

  if (dataOrder) {
    dataOrder.forEach(({ price, soni }) => {
      Cash = Number(price);
      Soni = Number(soni);
      Summa = Cash * Soni;
      TotalCash += Summa;
      Skidka = (TotalCash * num) / 100;
      TotalSkidka = TotalCash - Skidka;
    });
  }

  return (
    <>
      <div style={{ paddingTop: 10 }}>
        <h4
          style={{
            padding: "10px 0 10px 15px",
            color: "#ff3141",
            fontSize: 18,
          }}
        >
          Оформление заказа
        </h4>
        <Form
          onFinish={GetNewOrders}
          form={form}
          initialValues={{
            chegirma: 0,
          }}
        >
          <Form.Item
            name="dukon_id"
            label="Выберите клиента"
            rules={[{ required: true, message: "Выберите клиента" }]}
          >
            <Select
              showSearch
              style={{
                width: "100%",
              }}
              // placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={dukonlar.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />
          </Form.Item>
          <Form.Item name="chegirma" label="Скидка в процентах">
            <Input
              inputmode="numeric"
              value={num}
              //   keyboard={<NumberKeyboard />}
              className={style.ClientAddInp}
              onChange={(e) => setNum(e)}
            />
          </Form.Item>

          <Form.Item name="izoh" label="Коментарии к заказу">
            <TextArea
              className={style.ClientAddInp}
              maxLength={100}
              rows={2}
              showCount
            />
          </Form.Item>

          <Form.Item>
            <Button
              disabled={isdisabled}
              type="submit"
              block
              color="danger"
              size="large"
              style={{
                marginTop: 5,
                marginBottom: 10,
              }}
            >
              Сахранить заказ
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div>
        <table style={{ width: "70%", paddingTop: 15 }}>
          <tbody>
            <tr>
              <td style={{ paddingLeft: 15 }}>Сумма заказа</td>
              <td style={{ fontSize: 18 }}>{numberFormat(TotalCash)}</td>
            </tr>
            <tr>
              <td style={{ paddingLeft: 15 }}>Сумма скидки</td>
              <td style={{ fontSize: 18 }}>{numberFormat(Skidka) ?? 0}</td>
            </tr>
            <tr>
              <td style={{ paddingLeft: 15 }}>Сумма к оплате:</td>
              <td style={{ fontSize: 18 }}>{numberFormat(TotalSkidka)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
