import React from "react";
import { OrdersPost } from "./OrdersPost";
import { OrdersTable } from "./OrdersTable";

export default function OrdersDataTable() {
  return (
    <>
      <OrdersPost />
      <OrdersTable />
    </>
  );
}
