import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useAuth = create(
  persist(
    (set) => ({
      user: {},
      authUser: (data) =>
        set((state) => {
          return {
            user: {
              tokenTime: data.tokentime,
              token: data.token,
              id: data.id,
              name: data.fio,
              rol: data.rol,
            },
          };
        }),
      exitUser: () =>
        set((state) => {
          return {
            user: {
              tokenTime: null,
              token: null,
              id: null,
              name: null,
              rol: null,
            },
          };
        }),
    }),
    { name: "UserAuth" }
  )
);
