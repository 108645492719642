import React, { useContext } from "react";
import { CloseOutline } from "antd-mobile-icons";
import Swal from "sweetalert2";
import { OrdersProvider } from "../context/OrdersProvider";
import { useAvtorization } from "util/ZustandOrdersAdd/avtorization/useAvtorization";
import { requestFetch } from "Fetch/requestFetch";
import styles from "./Orders.module.css";

export const OrdersPriseMap = ({ product }) => {
  const { rol } = useAvtorization();
  const GetOrders = useContext(OrdersProvider);

  // удаляем данные из заказа
  const DeleteItem = (key) => {
    Swal.fire({
      title: "Вы уверены?",
      text: "точно удалить!",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "ДА",
      denyButtonText: `Нет`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        requestFetch({ path: "orders-item-delete", value: { id: key } })
          .then(function (res) {
            // console.log(res);
            Swal.fire({
              title: `Успешно удалено!`,
              icon: "success",
              button: "Спасибо",
            }); //   console.log(res.data);
            GetOrders();
          })
          .catch(function (error) {
            //   console.log(error);
            Swal.fire({
              title: "Что-то пошло не так!!!",
              icon: "warning",
              button: "Ок",
            });
            GetOrders();
          });
      } else if (result.isDenied) {
        Swal.fire("Хорошо как пожелаете", "", "info");
        GetOrders();
      }
    });
  };

  return product.map((i, index) => (
    <tr key={i.id} className={styles.PriceDataTr}>
      <td className={styles.PriceDataTd}>{index + 1}</td>
      <td className={styles.PriceDataTd}>{i.nomi}</td>
      <td className={styles.PriceDataTd}>{i.soni}</td>
      <td className={styles.PriceDataTd}>{i.price}</td>
      <td className={styles.PriceDataTd}>{i.price * i.soni}</td>
      {rol === "admin" || rol === "manager" || rol === "programist" ? (
        <td className={styles.PriceDataTd} onClick={() => DeleteItem(i.id)}>
          <CloseOutline color="var(--adm-color-danger)" />
        </td>
      ) : null}
    </tr>
  ));
};
