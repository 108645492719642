import { requestFetch } from "Fetch/requestFetch";
import { Broadcast } from "components/context/StoreProvider";
import React from "react";
import Swal from "sweetalert2";

export default function BroadCastState({ children }) {
  const onOrdersTransfer = (del_id, or_id, GetOrders) => {
    Swal.fire({
      title: "Вы уверены!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "ДА",
      denyButtonText: `Нет`,
    }).then((result) => {
      if (result.isConfirmed) {
        requestFetch({
          path: "add-eltuvchi-update",
          value: {
            eltuvchi_id: del_id,
            order_id: or_id,
          },
        })
          .then(function (res) {
            if (res.data.error === 0) {
              Swal.fire({
                title: `Заказ передан!`,
                icon: "success",
                confirmButtonText: "OK",
              });
              GetOrders();
            } else {
              Swal.fire({
                title: "Что-то пошло не так!!!",
                icon: "warning",
                confirmButtonText: "Ок",
              });
              GetOrders();
            }
            // console.log(res);
          })
          .catch(function (error) {
            Swal.fire({
              title: "Что-то пошло не так!!!",
              icon: "warning",
              confirmButtonText: "Ок",
            });
            GetOrders();
          });
      } else if (result.isDenied) {
        Swal.fire("Хорошо как пожелаете", "", "info");
      }
    });
  };
  return (
    <Broadcast.Provider value={{ onOrdersTransfer }}>
      {children}
    </Broadcast.Provider>
  );
}
