import {
  FileOutline,
  UnorderedListOutline,
  //   UserOutline,
} from "antd-mobile-icons";
import { FaWarehouse } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { TbTruckDelivery } from "react-icons/tb";
import { BsShop } from "react-icons/bs";
import { ExportOutlined } from "@ant-design/icons";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
export const State = {
  options: [
    {
      label: "Доставщик",
      value: "dostavka",
    },
    {
      label: "Торговый агент",
      value: "agent",
    },
    {
      label: "Менеджер",
      value: "manager",
    },
  ],
  adminTabs: [
    {
      id: "1",
      key: "/home",
      title: "Список",
      icon: <UnorderedListOutline />,
    },
    {
      id: "2",
      key: "/orders",
      title: "Заказы",
      icon: <FileOutline />,
    },
    {
      id: "3",
      key: "/personal",
      title: "Персонал",
      icon: <FiUsers />,
    },
    {
      id: "4",
      key: "/product",
      title: "Товары",
      icon: <FaWarehouse />,
    },
    {
      id: "5",
      key: "/delivery",
      title: "Доставка",
      icon: <TbTruckDelivery />,
    },
    {
      id: "6",
      key: "/debtors",
      title: "Должники",
      icon: <BsShop />,
    },
    {
      id: "7",
      key: "/shop",
      title: "Магазины",
      icon: <AiOutlineAppstoreAdd />,
    },
    {
      id: "8",
      key: "/login",
      title: "Выйти",
      icon: <ExportOutlined style={{ color: "red" }} />,
    },
  ],
  agentTabs: [
    {
      id: "2",
      key: "/orders",
      title: "Заказы",
      icon: <FileOutline />,
    },
    {
      id: "4",
      key: "/product",
      title: "Товары",
      icon: <FaWarehouse />,
    },
    {
      id: "6",
      key: "/debtors",
      title: "Должники",
      icon: <BsShop />,
    },
    {
      id: "7",
      key: "/shop",
      title: "Магазины",
      icon: <AiOutlineAppstoreAdd />,
    },
    {
      id: "8",
      key: "/login",
      title: "Выйти",
      icon: <ExportOutlined style={{ color: "red" }} />,
    },
  ],
  deliverTabs: [
    {
      id: "1",
      key: "/home",
      title: "Список",
      icon: <UnorderedListOutline />,
    },
    {
      id: "5",
      key: "/delivery",
      title: "Доставка",
      icon: <TbTruckDelivery />,
    },
    {
      id: "6",
      key: "/debtors",
      title: "Должники",
      icon: <BsShop />,
    },
    {
      id: "7",
      key: "/shop",
      title: "Магазины",
      icon: <AiOutlineAppstoreAdd />,
    },
    {
      id: "8",
      key: "/login",
      title: "Выйти",
      icon: <ExportOutlined style={{ color: "red" }} />,
    },
  ],
  // color="#00b578"
  workers: [
    {
      id: "1",
      date: "04.02.2022",
      name: "Шахбоз",
      tel: "(33) 646-52-14",
      rol: "доставщик",
      ishnarxi: "5%",
      se: "AA808080",
      adres: "Beruni 11/46",
      balans: 158000,
      login: "shaxboz22",
      pasword: "1111",
    },
    {
      id: "2",
      date: "04.02.2022",
      name: "Санжар",
      tel: "(33) 000-52-14",
      rol: "доставщик",
      ishnarxi: "5%",
      se: "AA808080",
      adres: "Lolazor 11/46",
      balans: 2285000,
      login: "shaxboz22",
      pasword: "1111",
    },
    {
      id: "3",
      date: "04.02.2022",
      name: "Мехродж",
      tel: "(22) 545-01-14",
      rol: "доставщик",
      ishnarxi: "5%",
      se: "AA000080",
      adres: "Samarkans 11/46",
      balans: 500000,
      login: "shaxboz22",
      pasword: "1111",
    },
    {
      id: "4",
      date: "04.02.2022",
      name: "Аслиддин",
      tel: "(22) 545-01-14",
      rol: "доставщик",
      ishnarxi: "5%",
      se: "AA000080",
      adres: "Samarkans 11/46",
      balans: 500000,
      login: "shaxboz22",
      pasword: "1111",
    },
    //  {
    //    id: "5",
    //    date: "04.02.2022",
    //    name: "Мехродж",
    //    tel: "(22) 545-01-14",
    //    rol: "доставщик",
    //    ishnarxi: "5%",
    //    se: "AA000080",
    //    adres: "Samarkans 11/46",
    //    balans: 500000,
    //    login: "shaxboz22",
    //    pasword: "1111",
    //  },
    //  {
    //    id: "6",
    //    date: "04.02.2022",
    //    name: "Мехродж",
    //    tel: "(22) 545-01-14",
    //    rol: "доставщик",
    //    ishnarxi: "5%",
    //    se: "AA000080",
    //    adres: "Samarkans 11/46",
    //    balans: 500000,
    //    login: "shaxboz22",
    //    pasword: "1111",
    //  },
    //  {
    //    id: "7",
    //    date: "04.02.2022",
    //    name: "Мехродж",
    //    tel: "(22) 545-01-14",
    //    rol: "доставщик",
    //    ishnarxi: "5%",
    //    se: "AA000080",
    //    adres: "Samarkans 11/46",
    //    balans: 500000,
    //    login: "shaxboz22",
    //    pasword: "1111",
    //  },
    //  {
    //    id: "8",
    //    date: "04.02.2022",
    //    name: "Мехродж",
    //    tel: "(22) 545-01-14",
    //    rol: "доставщик",
    //    ishnarxi: "5%",
    //    se: "AA000080",
    //    adres: "Samarkans 11/46",
    //    balans: 500000,
    //    login: "shaxboz22",
    //    pasword: "1111",
    //  },
    //  {
    //    id: "9",
    //    date: "04.02.2022",
    //    name: "Мехродж",
    //    tel: "(22) 545-01-14",
    //    rol: "доставщик",
    //    ishnarxi: "5%",
    //    se: "AA000080",
    //    adres: "Samarkans 11/46",
    //    balans: 500000,
    //    login: "shaxboz22",
    //    pasword: "1111",
    //  },
    //  {
    //    id: "10",
    //    date: "04.02.2022",
    //    name: "Мехродж",
    //    tel: "(22) 545-01-14",
    //    rol: "доставщик",
    //    ishnarxi: "5%",
    //    se: "AA000080",
    //    adres: "Samarkans 11/46",
    //    balans: 500000,
    //    login: "shaxboz22",
    //    pasword: "1111",
    //  },
    //  {
    //    id: "11",
    //    date: "04.02.2022",
    //    name: "Мехродж",
    //    tel: "(22) 545-01-14",
    //    rol: "доставщик",
    //    ishnarxi: "5%",
    //    se: "AA000080",
    //    adres: "Samarkans 11/46",
    //    balans: 500000,
    //    login: "shaxboz22",
    //    pasword: "1111",
    //  },
    //  {
    //    id: "12",
    //    date: "04.02.2022",
    //    name: "Мехродж",
    //    tel: "(22) 545-01-14",
    //    rol: "доставщик",
    //    ishnarxi: "5%",
    //    se: "AA000080",
    //    adres: "Samarkans 11/46",
    //    balans: 500000,
    //    login: "shaxboz22",
    //    pasword: "1111",
    //  },
    //  {
    //    id: "13",
    //    date: "04.02.2022",
    //    name: "Мехродж",
    //    tel: "(22) 545-01-14",
    //    rol: "доставщик",
    //    ishnarxi: "5%",
    //    se: "AA000080",
    //    adres: "Samarkans 11/46",
    //    balans: 500000,
    //    login: "shaxboz22",
    //    pasword: "1111",
    //  },
    //  {
    //    id: "14",
    //    date: "04.02.2022",
    //    name: "Мехродж",
    //    tel: "(22) 545-01-14",
    //    rol: "доставщик",
    //    ishnarxi: "5%",
    //    se: "AA000080",
    //    adres: "Samarkans 11/46",
    //    balans: 500000,
    //    login: "shaxboz22",
    //    pasword: "1111",
    //  },
    //  {
    //    id: "15",
    //    date: "04.02.2022",
    //    name: "Мехродж",
    //    tel: "(22) 545-01-14",
    //    rol: "доставщик",
    //    ishnarxi: "5%",
    //    se: "AA000080",
    //    adres: "Samarkans 11/46",
    //    balans: 500000,
    //    login: "shaxboz22",
    //    pasword: "1111",
    //  },
    //  {
    //    id: "16",
    //    date: "04.02.2022",
    //    name: "Мехродж",
    //    tel: "(22) 545-01-14",
    //    rol: "доставщик",
    //    ishnarxi: "5%",
    //    se: "AA000080",
    //    adres: "Samarkans 11/46",
    //    balans: 500000,
    //    login: "shaxboz22",
    //    pasword: "1111",
    //  },
    //  {
    //    id: "17",
    //    date: "04.02.2022",
    //    name: "Мехродж",
    //    tel: "(22) 545-01-14",
    //    rol: "доставщик",
    //    ishnarxi: "5%",
    //    se: "AA000080",
    //    adres: "Samarkans 11/46",
    //    balans: 500000,
    //    login: "shaxboz22",
    //    pasword: "1111",
    //  },
    //  {
    //    id: "18",
    //    date: "04.02.2022",
    //    name: "Мехродж",
    //    tel: "(22) 545-01-14",
    //    rol: "доставщик",
    //    ishnarxi: "5%",
    //    se: "AA000080",
    //    adres: "Samarkans 11/46",
    //    balans: 500000,
    //    login: "shaxboz22",
    //    pasword: "1111",
    //  },
  ],
  product: [
    {
      id: 1,
      name: "печенье",
      price: "10500",
      remainder: "105",
      unit: "кг",
    },
    {
      id: 2,
      name: "печенье песочные",
      price: "8600",
      remainder: "96",
      unit: "кг",
    },
    {
      id: 3,
      name: "печенье шоколадные",
      price: "5500",
      remainder: "36",
      unit: "кг",
    },
    {
      id: 4,
      name: "печенье мармеладные",
      price: "18800",
      remainder: "220",
      unit: "кг",
    },
    {
      id: 5,
      name: "рулет",
      price: "12200",
      remainder: "106",
      unit: "шт",
    },
    {
      id: 6,
      name: "пирог",
      price: "15000",
      remainder: "20",
      unit: "шт",
    },
  ],
  newOrders: [
    {
      id: "1",
      dukon_name: "MiniMarket",
      agent_id: "1",
      summa: 15500000,
      eltuvchi_id: "1",
      sana: "26.12.2022",
      status: "В ожидании",
      izoh: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae, suscipit?",
      chegirma: "5%",
      tulov_turi: "наличный",
      qarz: 6850000,
      naqd: 5000000,
      plastik: 10000000,
      karta: 500000,
      price: [
        {
          id: 1,
          name: "Печенье",
          soni: 20,
          unit: "кг",
          pay: 15500,
        },
        {
          id: 2,
          name: "Печенье песочные",
          soni: 50,
          unit: "кг",
          pay: 11300,
        },
        {
          id: 3,
          name: "Печенье шоколадные",
          soni: 50,
          unit: "кг",
          pay: 11300,
        },
        {
          id: 4,
          name: "Печенье мармеладные",
          soni: 30,
          unit: "кг",
          pay: 7000,
        },
        {
          id: 5,
          name: "Печенье мармеладные",
          soni: 30,
          unit: "кг",
          pay: 7000,
        },
        {
          id: 6,
          name: "Печенье мармеладные",
          soni: 30,
          unit: "кг",
          pay: 7000,
        },
        {
          id: 7,
          name: "Печенье мармеладные",
          soni: 30,
          unit: "кг",
          pay: 7000,
        },
        {
          id: 8,
          name: "Печенье мармеладные",
          soni: 30,
          unit: "кг",
          pay: 7000,
        },
        {
          id: 9,
          name: "Печенье мармеладные",
          soni: 30,
          unit: "кг",
          pay: 7000,
        },
        {
          id: 10,
          name: "Печенье мармеладные",
          soni: 30,
          unit: "кг",
          pay: 7000,
        },
        {
          id: 11,
          name: "Печенье мармеладные",
          soni: 30,
          unit: "кг",
          pay: 7000,
        },
        {
          id: 12,
          name: "Печенье песочные",
          soni: 50,
          unit: "кг",
          pay: 11300,
        },
        {
          id: 13,
          name: "Печенье песочные",
          soni: 50,
          unit: "кг",
          pay: 11300,
        },
        {
          id: 14,
          name: "Печенье песочные",
          soni: 50,
          unit: "кг",
          pay: 11300,
        },
        {
          id: 15,
          name: "Печенье песочные",
          soni: 50,
          unit: "кг",
          pay: 11300,
        },
        {
          id: 16,
          name: "Печенье песочные",
          soni: 50,
          unit: "кг",
          pay: 11300,
        },
        {
          id: 17,
          name: "Печенье песочные",
          soni: 50,
          unit: "кг",
          pay: 11300,
        },
        {
          id: 18,
          name: "Печенье песочные",
          soni: 50,
          unit: "кг",
          pay: 11300,
        },
        {
          id: 19,
          name: "Печенье песочные",
          soni: 50,
          unit: "кг",
          pay: 11300,
        },
        {
          id: 20,
          name: "Печенье песочные",
          soni: 50,
          unit: "кг",
          pay: 11300,
        },
        {
          id: 21,
          name: "Печенье песочные",
          soni: 50,
          unit: "кг",
          pay: 11300,
        },
        {
          id: 22,
          name: "Печенье песочные",
          soni: 50,
          unit: "кг",
          pay: 11300,
        },
      ],
    },
    {
      id: "2",
      dukon_id: "2",
      dukon_name: "Wello",
      agent_id: "1",
      eltuvchi_id: "2",
      summa: 1150000,
      sana: "26.12.2022",
      status: "В ожидании",
      izoh: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae, suscipit?",
      chegirma: "",
      tulov_turi: "наличный",
      qarz: 100000,
      naqd: 150000,
      plastik: 1000000,
      karta: "",
      price: [],
    },
    {
      id: "3",
      dukon_id: "3",
      dukon_name: "Farovon",
      agent_id: "21",
      eltuvchi_id: "3",
      summa: 2000000,
      sana: "26.12.2022",
      status: "В ожидании",
      izoh: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae, suscipit?",
      chegirma: "",
      tulov_turi: "наличный",
      qarz: "",
      naqd: 490000,
      plastik: "",
      karta: 1510000,
      price: [],
    },
    {
      id: "4",
      dukon_id: "4",
      dukon_name: "Germes",
      agent_id: "4",
      eltuvchi_id: "4",
      summa: 2800000,
      sana: "26.12.2022",
      status: "В ожидании",
      izoh: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae, suscipit?",
      chegirma: "",
      tulov_turi: "наличный",
      qarz: "",
      naqd: 800000,
      plastik: "",
      karta: 2000000,
      price: [],
    },
    {
      id: "5",
      dukon_id: "5",
      dukon_name: "Optovik",
      aget_id: "5",
      eltuvchi_id: "5",
      summa: 2800000,
      sana: "26.12.2022",
      status: "В ожидании",
      izoh: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae, suscipit?",
      chegirma: "",
      tulov_turi: "наличный",
      qarz: "",
      naqd: 800000,
      plastik: "",
      karta: 2000000,
      price: [],
    },
  ],
  region: [
    {
      value: "Samarqand",
      label: "Самарканд",
      children: [
        {
          value: "Bulungur",
          label: "Булунгур",
        },
        {
          value: "Ishtixon",
          label: "Иштихон",
        },
        {
          value: "Jomboy",
          label: "Жомбой",
        },
        {
          value: "Kattaqurgon sh.",
          label: "Каттакурган г.",
        },
        {
          value: "Kattaqurgon t.",
          label: "Каттакурган р.",
        },
        {
          value: "Narpay",
          label: "Нарпай",
        },
        {
          value: "Nurobod",
          label: "Нуробод",
        },
        {
          value: "Oqdaryo",
          label: "Окдарё",
        },
        {
          value: "Pastdargon",
          label: "Пастдаргон",
        },
        {
          value: "Пахтачи",
          label: "Пахтачи",
        },

        {
          value: "Payariq",
          label: "Пайарик",
        },
        {
          value: "Qoshrabot",
          label: "Кушработ",
        },
        {
          value: "Samarqand sh.",
          label: "Самарканд г.",
        },
        {
          value: "Tayloq",
          label: "Тайлок",
        },
        {
          value: "Urgut",
          label: "Ургут",
        },
      ],
    },
  ],
  client: [
    {
      dukon_id: "1",
      dukon_name: "Opelsin",
      viloyat: "Samarkand",
      tuman: "Samarkand",
      adres: "test 36/40",
      egasi: "Botir Botirov",
      tel: "998905001001",
    },
    {
      dukon_id: "2",
      dukon_name: "Marmelad",
      viloyat: "Samarkand",
      tuman: "Urgut",
      adres: "Pastepa 10",
      egasi: "Anvar",
      tel: "998901251701",
    },
    {
      dukon_id: "3",
      dukon_name: "Shokolad",
      viloyat: "Samarkand",
      tuman: "Tayloq",
      adres: "Mirzo 47",
      egasi: "AliAkbar",
      tel: "998335561701",
    },
  ],
};
