import { PullToRefresh, Toast } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";
import React from "react";

const statusRecord = {
  pulling: "сильно тянуть",
  canRelease: "Отпустите для обновления",
  refreshing: "Загрузка...",
  complete: "Отлично",
};

export default function Refresh({ children, fnc, data, dataId }) {
  async function doRefresh() {
    fnc(dataId);
    await sleep(1000);
    if (data.length !== 0) {
      Toast.show({
        icon: "success",
        content: "Обнавлено!",
      });
    } else {
      Toast.show({
        icon: "fail",
        content: "Не удалось обновить",
      });
      throw new Error("Не удалось обновить");
    }
  }
  return (
    <PullToRefresh
      onRefresh={doRefresh}
      renderText={(status) => {
        return <div>{statusRecord[status]}</div>;
      }}
    >
      {children}
    </PullToRefresh>
  );
}
