import { Button, Popup } from "antd-mobile";
import React from "react";

export default function FilterPopup({ children, name, visible2, setVisible2 }) {
  return (
    <>
      <Button
        block
        size="small"
        color="primary"
        onClick={() => {
          setVisible2(true);
        }}
      >
        {name}
      </Button>
      <Popup
        visible={visible2}
        onMaskClick={() => {
          setVisible2(false);
        }}
        position="top"
        bodyStyle={{ height: "max-content" }}
      >
        {children}
      </Popup>
    </>
  );
}
