import React, { useContext } from "react";
import { PullToRefresh, List, Tag, Toast } from "antd-mobile";
import { OrdersСonfirmDelete } from "./OrdersСonfirmDelete";
import { DeleteOutline } from "antd-mobile-icons";
import { OrdersPriseMap } from "./OrdersPriseMap";
import { useNavigate } from "react-router-dom";
import { sleep } from "antd-mobile/es/utils/sleep";
import Swal from "sweetalert2";
import { OrdersProvider } from "../context/OrdersProvider";
import { PopupModal } from "../ModalComponent/ModalPopup/PopupModal";
import { EyeOutline } from "antd-mobile-icons";
import { useAvtorization } from "util/ZustandOrdersAdd/avtorization/useAvtorization";
import { numberFormat } from "util/numberFormat";
import styles from "./Orders.module.css";
import { requestFetch } from "Fetch/requestFetch";

export const OrdersDetailed = ({
  id,
  date,
  agent_id,
  dukon_name,
  summa,
  agent_summa,
  status,
  izoh,
  chegirma,
  products,
  agent_name,
  finSum,
}) => {
  const { rol } = useAvtorization();
  const OrdersPro = useContext(OrdersProvider);

  const history = useNavigate();
  // текст для рефреш функцыя
  const statusRecord = {
    pulling: "сильно тянуть",
    canRelease: "Отпустите для обновления",
    refreshing: "Загрузка...",
    complete: "Отлично",
  };

  // удаляем заказ
  const DeleteOrders = () => {
    Swal.fire({
      title: "Вы уверены?",
      text: "точно удалить!",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "ДА",
      denyButtonText: `Нет`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        requestFetch({ path: "orders-delete", value: { id: id } })
          .then(function (res) {
            // console.log("res", res);
            Swal.fire({
              title: `Успешно удалено!`,
              icon: "success",
              button: "Спасибо",
            }); //   console.log(res.data);
            OrdersPro();
            history("/home");
          })
          .catch(function (error) {
            //   console.log(error);
            Swal.fire({
              title: "Что-то пошло не так!!!",
              icon: "warning",
              button: "Ок",
            });
          });
      } else if (result.isDenied) {
        Swal.fire("Хорошо как пожелаете", "", "info");
      }
    });
  };

  // подтверждаем заказ
  // order-confirm-manager.php
  const ConfirmOrders = () => {
    Swal.fire({
      title: "Вы уверены?",
      text: "Принять заказ!",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "ДА",
      denyButtonText: `Нет`,
    }).then((result) => {
      if (result.isConfirmed) {
        requestFetch({ path: "order-confirm-manager", value: { id: id } })
          .then(function (res) {
            Swal.fire({
              title: `Заказ принят!`,
              icon: "success",
              button: "Спасибо",
            }); //   console.log(res.data);
            OrdersPro();
          })
          .catch(function (error) {
            //   console.log(error);
            Swal.fire({
              title: "Что-то пошло не так!!!",
              icon: "warning",
              button: "Ок",
            });
          });
      } else if (result.isDenied) {
        Swal.fire("Хорошо как пожелаете", "", "info");
      }
    });
  };

  // рефреш функцыя
  async function doRefresh() {
    await sleep(1000);
    if (products.length !== 0) {
      Toast.show({
        icon: "success",
        content: "Обнавлено!",
      });
    } else {
      Toast.show({
        icon: "fail",
        content: "Не удалось обновить",
      });
      throw new Error("Не удалось обновить");
    }
  }

  return (
    <PopupModal name={<EyeOutline style={{ fontSize: 18 }} />}>
      <PullToRefresh
        onRefresh={doRefresh}
        renderText={(status) => {
          return <div>{statusRecord[status]}</div>;
        }}
      >
        <div className={styles.container}>
          <List
            header={
              <div className={styles.container__headers}>
                <div>
                  <h3>{dukon_name}</h3>
                </div>
                {status === "new" ? (
                  <div className={styles.container__headers__tag}>
                    {" "}
                    <Tag color="danger">В ожидании</Tag>
                  </div>
                ) : (
                  <div className={styles.container__headers__tag}>
                    <Tag color="success">Принято</Tag>
                  </div>
                )}
              </div>
            }
            mode="card"
          >
            <List.Item style={{ color: "#ff8f1f" }}>
              Сумма заказа: {numberFormat(summa)}
            </List.Item>
            <List.Item style={{ color: "#ff8f1f" }}>
              Сумма cсо скидкой: {numberFormat(finSum)}
            </List.Item>
            <List.Item style={{ color: "#ff8f1f" }}>
              Скидка: {chegirma}
            </List.Item>
            <List.Item style={{ color: "#ff8f1f" }}>
              Коментарии к заказу: {izoh}
            </List.Item>
            <List.Item style={{ color: "#ff8f1f" }}>
              Торговый агент: <span>{agent_name}</span>
            </List.Item>
            {/* <List.Item style={{ color: "#ff8f1f" }}>
              Сумма агенту: {priceTo(agent_summa)}
            </List.Item> */}
            <List.Item>
              {/* подтвердить или удалить заказ */}
              {rol === "admin" || rol === "manager" || rol === "programist" ? (
                <OrdersСonfirmDelete
                  {...{ DeleteOrders }}
                  {...{ ConfirmOrders }}
                />
              ) : null}
            </List.Item>
          </List>

          <table
            style={{
              width: "100%",
            }}
          >
            <thead>
              <tr>
                <th style={{ color: "#00b578" }}>№</th>
                <th style={{ color: "#00b578" }}>Наименование</th>
                <th style={{ color: "#00b578" }}>Каличество</th>
                <th style={{ color: "#00b578" }}>Цена</th>
                <th style={{ color: "#00b578" }}>Сумма</th>
                {rol === "admin" ||
                rol === "manager" ||
                rol === "programist" ? (
                  <th>
                    <DeleteOutline color="var(--adm-color-danger)" />
                  </th>
                ) : null}
              </tr>
            </thead>
          </table>
          <div className={styles.scrollPart}>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                marginBottom: 35,
              }}
            >
              <tbody>
                <OrdersPriseMap product={products} summa={summa} />
              </tbody>
            </table>
          </div>
        </div>
      </PullToRefresh>
    </PopupModal>
  );
};
