import { Button, Popup } from "antd-mobile";
import { CloseOutline } from "antd-mobile-icons";
import css from "./CatalogStyle.module.css";
import React from "react";

export default function AddModal({ children, visible, setVisible }) {
  return (
    <Popup
      visible={visible}
      onMaskClick={() => {
        setVisible(false);
      }}
      position="top"
      bodyStyle={{ paddingTop: 50 }}
      //   className="top__modal-wrapper"
    >
      <div className="top__modal-btn-fixed">
        <Button
          onClick={() => {
            setVisible(false);
          }}
          className="top__modal-btn"
          style={{ color: "#1677ff" }}
        >
          <CloseOutline />
        </Button>
      </div>
      <div className={css.PopupData}> {children}</div>
    </Popup>
  );
}
