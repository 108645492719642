import React, { useState } from "react";
import { Form, Input } from "antd";
import { AutoCenter, Button, NumberKeyboard, VirtualInput } from "antd-mobile";
import PhoneInput from "react-phone-input-2";
import { Select } from "antd";
import Swal from "sweetalert2";
import styles from "../Orders/Orders.module.css";
import useViloyat from "util/viloyat/useViloyat";
import useTuman from "util/tuman/useTuman";
import { requestFetch } from "Fetch/requestFetch";

export const NewClientAdd = () => {
  const { tuman } = useTuman();
  const { viloyat } = useViloyat();
  const [form] = Form.useForm();
  const [telefon, setValue] = useState("");

  //  добавляем нового клиента
  const AddClient = (value) => {
    Swal.fire({
      title: "Добавить нового клиента?",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Добавить",
      denyButtonText: `Отмена`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        requestFetch({ path: "dukonlar-add", value: value })
          .then(function (response) {
            // console.log(response.data);
            if (response.data.error === 0) {
              Swal.fire({
                title: `Отлично`,
                text: "Новый клиент добавлен",
                icon: "success",
                button: "Ok",
              });
              form.resetFields();
            }
          })
          .catch(function (error) {
            Swal.fire("Что-то пошло не так!!", "", "info");
          });
      } else if (result.isDenied) {
        Swal.fire("Хорошо как пожелаете", "", "info");
      }
    });
  };

  // выводим название viloyatlar
  const options = viloyat.map((i) => (
    <Select.Option key={i.id} value={i.id}>
      {i.nomi}
    </Select.Option>
  ));

  // получаем значения из select viloyatlar
  const [filterTuman, setFilterTuman] = useState();
  const ValueViloyat = (val) => {
    setFilterTuman(val);
  };

  //передаём значение в select tuman
  const tumanOptions = tuman.map((i) =>
    filterTuman === i.vil_id ? (
      <Select.Option key={i.id} value={i.id}>
        {i.nomi}
      </Select.Option>
    ) : null
  );

  return (
    <>
      <AutoCenter>
        <h3 style={{ margin: 0, padding: 5, fontSize: 16, color: "#00b578" }}>
          Новый клиент
        </h3>
      </AutoCenter>

      <Form layout="vertical" onFinish={AddClient} form={form}>
        <Form.Item
          name="name"
          label="Название магазина или имя клиента"
          rules={[{ required: true, message: "Введите название магазина!" }]}
        >
          <Input size="large" />
        </Form.Item>

        <Form.Item name="viloyat_id" label="Выберите город">
          <Select size="large" onChange={ValueViloyat}>
            {options}
          </Select>
        </Form.Item>
        <Form.Item label="Выберите регион" name="tuman_id">
          <Select size="large">{tumanOptions}</Select>
          {/* <Select
            showSearch
            style={{
              width: "100%",
            }}
            // placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={tuman.map((item) => ({
              value: item.id,
              label: item.nomi,
            }))}
          /> */}
        </Form.Item>
        <Form.Item
          name="manzil"
          label="Улица, дом, номер"
          rules={[{ required: true, message: "Введите адрес!" }]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item name="fio" label="Директор или менеджер">
          <Input size="large" />
        </Form.Item>
        <Form.Item name="balans" label="Баланс">
          <VirtualInput
            keyboard={<NumberKeyboard />}
            className={styles.ClientAddInp}
          />
        </Form.Item>
        <Form.Item name="qarz" label="Долг">
          <VirtualInput
            keyboard={<NumberKeyboard />}
            className={styles.ClientAddInp}
          />
        </Form.Item>

        <Form.Item
          label="Номер(тел)"
          name="telefon"
          rules={[{ required: true, message: "введите номер рабочего!" }]}
        >
          <PhoneInput
            country={"uz"}
            value={telefon}
            onChange={(tel) => setValue(tel)}
            placeholder="Номер телефона"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="submit"
            block
            color="success"
            size="large"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            Добавить
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
