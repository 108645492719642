import React from "react";
import { PullToRefresh, List, Toast } from "antd-mobile";
import { DeliveryReturn } from "./DeliveryReturn";
import { ModalTop } from "../ModalComponent/ModalTop/ModalTop";
import { DeliverypriceMap } from "./DeliverypriceMap";
import styles from "../Orders/Orders.module.css";
import { sleep } from "antd-mobile/es/utils/sleep";
import { DeliveryPayment } from "./DeliveryPayment";
import { numberFormat } from "util/numberFormat";
import css from "./Delivery.module.css";

export const DeliveryDetailes = ({
  id,
  dukon_name,
  total_skidka,
  izoh,
  products,
  GetOrders,
  eltuvchi_id,
  d_balans,
  status,
}) => {
  // const {token} = useAvtorization();
  // текст для рефреш функцыя
  const statusRecord = {
    pulling: "сильно тянуть",
    canRelease: "Отпустите для обновления",
    refreshing: "Загрузка...",
    complete: "Отлично",
  };

  // рефреш функцыя
  async function doRefresh() {
    await sleep(1000);
    if (products.length !== 0) {
      Toast.show({
        icon: "success",
        content: "Обнавлено!",
      });
    } else {
      Toast.show({
        icon: "fail",
        content: "Не удалось обновить",
      });
      throw new Error("Не удалось обновить");
    }
  }

  return (
    <ModalTop name="Подробнее">
      <PullToRefresh
        onRefresh={doRefresh}
        renderText={(status) => {
          return <div>{statusRecord[status]}</div>;
        }}
      >
        <div className={styles.container}>
          <List
            header={
              <div className={styles.container__headers}>
                <div>
                  <h2>{dukon_name}</h2>
                </div>
              </div>
            }
            mode="card"
          >
            <List.Item style={{ color: "#ff8f1f" }}>
              Баланс заказчика: {numberFormat(d_balans)}
            </List.Item>
            <List.Item style={{ color: "#ff8f1f" }}>
              Сумма заказа: {numberFormat(total_skidka)}
            </List.Item>
            <List.Item style={{ color: "#ff8f1f" }}>
              Коментарии к заказу: {izoh}
            </List.Item>
            <List.Item>
              <div className={css.DeliveryBlockBtn}>
                {status !== "done" && status === "eltuvchi" ? (
                  <>
                    {/* возврат заказа */}
                    <DeliveryReturn
                      id={id}
                      product={products}
                      GetOrders={GetOrders}
                    />
                    {/* оплата заказа */}
                    <DeliveryPayment
                      total_skidka={total_skidka}
                      id={id}
                      GetOrders={GetOrders}
                      eltuvchi_id={eltuvchi_id}
                    />
                  </>
                ) : null}
              </div>
            </List.Item>
          </List>

          <table
            style={{
              width: "100%",
            }}
          >
            <thead>
              <tr>
                <th style={{ color: "#00b578", textAlign: "center" }}>№</th>
                <th style={{ color: "#00b578", textAlign: "center" }}>
                  Наименование
                </th>
                <th style={{ color: "#00b578", textAlign: "center" }}>
                  Количество
                </th>
              </tr>
            </thead>
          </table>
          <div className={styles.scrollPart}>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <DeliverypriceMap product={products} />
              </tbody>
            </table>
          </div>
        </div>
      </PullToRefresh>
    </ModalTop>
  );
};
