import React, { useState, useContext } from "react";
import { ModalTop } from "../ModalComponent/ModalTop/ModalTop";
import {
  Form,
  Input,
  Button,
  Selector,
  NumberKeyboard,
  VirtualInput,
} from "antd-mobile";
import { BsPersonPlusFill } from "react-icons/bs";
import { TabsProvider } from "../context/StoreProvider";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import styles from "./Workers.module.css";
import { requestFetch } from "Fetch/requestFetch";

export function WorkersAdd(props) {
  const [telefon, setValue] = useState("");
  const State = useContext(TabsProvider);
  const [form] = Form.useForm();
  const [select, setSelect] = useState("");

  const AddWorker = (values) => {
    values.rol = select;
    Swal.fire({
      title: "Добавить нового рабочего?",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Добавить",
      denyButtonText: `Отмена`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        requestFetch({ path: "add-user", value: values })
          .then(function (response) {
            // console.log(response.data);
            if (response.data.error === 0) {
              Swal.fire({
                title: `Отлично`,
                text: "Новый рабочий добавлен",
                icon: "success",
                button: "Спасибо",
              });
              form.resetFields();
              props.FetchWorkData();
            }
          })
          .catch(function (error) {
            Swal.fire("Что-то пошло не так!!", "", "info");
            console.log(error);
          });
      } else if (result.isDenied) {
        Swal.fire("Хорошо как пожелаете", "", "info");
      }
    });
  };

  return (
    <ModalTop name={<BsPersonPlusFill />}>
      <Form
        layout="vertical"
        onFinish={AddWorker}
        form={form}
        autoComplete="off"
      >
        <Form.Header>Добавить нового рабочего</Form.Header>
        <Form.Item
          name="fio"
          rules={[{ required: true, message: "введите имя рабочего!" }]}
        >
          <Input placeholder="Введите имя рабочего" />
        </Form.Item>
        {/* <Form.Item
          name="addres"
          rules={[{ required: true, message: "введите адрес проживания!" }]}
        >
          <Input placeholder="Адрес проживания" />
        </Form.Item> */}

        <Form.Item name="login">
          <Input
            placeholder="Логин"
            rules={[{ required: true, message: "Введите логин!" }]}
          />
        </Form.Item>
        <Form.Item
          name="parol"
          rules={[{ required: true, message: "Введите пароль!" }]}
        >
          <Input placeholder="Пароль" />
        </Form.Item>
        <Form.Item name="balans">
          <VirtualInput
            placeholder="Баланс"
            keyboard={<NumberKeyboard className={styles.keyboardNumber} />}
          />
        </Form.Item>

        {/* <Form.Item
          name="tel"
          rules={[{ required: true, message: "введите номер рабочего!" }]}
        >
          <PhoneInput
            country={"uz"}
            value={telefon}
            onChange={(tel) => setValue(tel)}
            placeholder="Номер телефона"
          />
        </Form.Item> */}

        <Form.Item
          //  name="rol"
          rules={[{ required: true, message: "выберите роль рабочего!" }]}
        >
          <Selector
            options={State.options}
            columns={3}
            // onChange={(arr, extend) => console.log(extend.items[0].value)}
            onChange={(arr, extend) => setSelect(extend.items[0].value)}
          />
        </Form.Item>
        <Form.Item
          name="pasport"
          rules={[{ required: true, message: "введите номер рабочего!" }]}
        >
          <PhoneInput
            country={"uz"}
            value={telefon}
            onChange={(tel) => setValue(tel)}
            placeholder="Номер телефона"
          />
        </Form.Item>
        <Form.Item>
          <Button block type="submit" color="success">
            Добавить
          </Button>
        </Form.Item>
      </Form>
    </ModalTop>
  );
}
