import { useAuth } from "./userAvtorization";

export function useAvtorization() {
  const { fio, rol, id, token, tokentime } = useAuth((state) => state.user);
  return {
    isAuth: !!token,
    fio,
    rol,
    id,
    token,
    tokentime,
  };
}
