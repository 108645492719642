import React from "react";
import { List } from "antd-mobile";
import { DotLoading, Button, Space } from "antd-mobile";
import { DeleteOutline } from "antd-mobile-icons";
import { numberFormat } from "util/numberFormat";
import { useUpdateOpders } from "util/ZustandOrdersAdd/OrdersAddNewItem/OrdersAddNewItemState";
import style from "../Orders.module.css";

export const OrdersItemTable = () => {
  const orderItem = useUpdateOpders((state) => state.orderItem);
  const removeItem = useUpdateOpders((state) => state.removeItem);

  // выводим данные заказа в талицу
  // eslint-disable-next-line
  let datas = orderItem.map((e) => (
    <List.Item
      key={e.product_id}
      description={`каличество:  ${e.soni}  цена: ${numberFormat(
        e.price
      )} сумма: ${numberFormat(e.price * e.soni)}`}
      extra={
        <Space direction="vertical">
          <Button
            onClick={() => removeItem(e.product_id)}
            size="mini"
            color="danger"
          >
            <DeleteOutline />
          </Button>
        </Space>
      }
    >
      {e.name}
    </List.Item>
  ));

  // выводим общую сумму заказа
  let TotalCash = 0;
  let Cash = 0;
  let Soni = 0;
  let Summa = 0;

  orderItem.forEach(({ price, soni }) => {
    Cash = Number(price);
    Soni = Number(soni);
    Summa = Cash * Soni;
    TotalCash += Summa;
  });

  // Loading
  if (orderItem.length === 0) {
    return (
      <div className={style.BlockPriceLoading}>
        <DotLoading color="currentColor" />
      </div>
    );
  }

  return (
    <>
      <div>
        <List>{datas}</List>
      </div>
      <div style={{ width: "100%" }}>
        <table style={{ width: "70%", paddingTop: 15 }}>
          <tbody>
            <tr>
              <td style={{ paddingLeft: 15 }}>Сумма</td>
              <td style={{ fontSize: 18 }}>{numberFormat(TotalCash)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
