import React from "react";
import { Login } from "./components/Login/Login";
import { Routes, Route, Navigate } from "react-router-dom";
import { GlobalApp } from "./components/GlobalApp/GlobalApp";
import { State } from "./Store/Store";
import { TabsProvider } from "./components/context/StoreProvider";
import { ConfigProvider } from "antd";
import ruRU from "antd/es/locale/ru_RU";
import { OrdersReportOrderSingle } from "./components/Orders/OrdersDebtors/OrdersReportOrderSingle.jsx";
import Home from "./components/Home/Home";
import { OrdersConfirmed } from "./components/Orders/OrdersTransfer/OrdersConfirmed";
import { Orders } from "./components/Orders/Orders";
import { Workers } from "./components/Workers/Workers";
// import { PdoductList } from "./components/Product/PdoductList";
import { Delivery } from "./components/Delivery/Delivery";
import { OrdersReport } from "./components/Orders/OrdersDebtors/OrdersReport";
// import OrdersAddCard from "./components/Orders/OrdersAdd/OrdersProductCard.jsx";
import CategoryState from "util/Category/CategoryState";
import GetProductState from "util/Product/GetProductState";
import { OrdersPage } from "components/Orders/OrdersAdd/OrdersPage";
import { useAvtorization } from "util/ZustandOrdersAdd/avtorization/useAvtorization";
import OrdersDataTable from "components/Orders/OrdersAdd/OrdersDataTable";
import ShopState from "util/shop/ShopState";
import "./App.css";
import { NewClientAdd } from "components/Shop/NewClientAdd";
import ViloyatState from "util/viloyat/ViloyatState";
import TumanState from "util/tuman/TumanState";
import BalansGoodsState from "util/BalansGoods/BalansGoodsState";
import BroadCastState from "util/Broadcast/BroadCastState";

const App = () => {
  const { rol } = useAvtorization();

  return (
    <TabsProvider.Provider value={State}>
      <ConfigProvider locale={ruRU}>
        <CategoryState>
          <ShopState>
            <GetProductState>
              <ViloyatState>
                <TumanState>
                  <BalansGoodsState>
                    <BroadCastState>
                      <Routes>
                        <Route path="/" element={<GlobalApp />}>
                          <Route index path="/home" element={<Home />} />
                          <Route
                            path="/confirmed"
                            element={<OrdersConfirmed />}
                          />
                          <Route path="/orders" element={<Orders />} />
                          {/* <Route path="/me" element={<PersonalCenter />} /> */}
                          <Route
                            path="/personal"
                            element={
                              rol === "admin" || rol === "programist" ? (
                                <Workers />
                              ) : (
                                <Navigate to={"/delivery"} />
                              )
                            }
                          />
                          <Route path="/product" element={<OrdersPage />} />
                          <Route
                            path={"/product/order"}
                            element={<OrdersDataTable />}
                          />
                          <Route
                            path="/delivery"
                            element={
                              rol === "agent" ? (
                                <Navigate to={"/product"} />
                              ) : (
                                <Delivery />
                              )
                            }
                          />
                          <Route path="/debtors" element={<OrdersReport />} />
                          <Route
                            path={"/debtors/:id"}
                            element={<OrdersReportOrderSingle />}
                          />
                          <Route path={"/shop"} element={<NewClientAdd />} />
                        </Route>
                        <Route path="/login" element={<Login />} />
                      </Routes>
                    </BroadCastState>
                  </BalansGoodsState>
                </TumanState>
              </ViloyatState>
            </GetProductState>
          </ShopState>
        </CategoryState>
      </ConfigProvider>
    </TabsProvider.Provider>
  );
};

export default App;
