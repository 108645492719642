import { Form, Input } from "antd";
import { Button } from "antd-mobile";
import React from "react";

export default function FormAddItem({ onFinish, form, inpRef }) {
  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        name="soni"
        label="Введите каличество!"
        rules={[{ required: true, message: "Введите каличество!" }]}
      >
        <Input
          ref={inpRef}
          type="number"
          placeholder=""
          autoComplete="off"
          style={{ padding: "5px 0 5px 5px", fontSize: 18 }}
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="submit"
          block
          color="danger"
          size="large"
          style={{ marginTop: 10, marginBottom: 10 }}
        >
          Добавить
        </Button>
      </Form.Item>
    </Form>
  );
}
