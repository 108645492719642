// import { Spin } from "antd";
import React from "react";
import { Skeleton } from "antd-mobile";
import "./loding.css";
const Loding = () => {
  //   const example = {
  //     height: "max-content",
  //     margin: "20px 0",
  //     padding: "30px 50px",
  //     textAlign: "center",
  //     background: "rgba(0, 0, 0, 0.05)",
  //     borderRadius: "4px",
  //   };

  return (
    //  <div style={example}>
    //    <Spin />
    //  </div>
    <div className="block__custom-skeleton">
      <Skeleton lineCount={15} animated className="customSkeleton" />
      <Skeleton lineCount={15} animated className="customSkeleton" />
      <Skeleton lineCount={15} animated className="customSkeleton" />
      <Skeleton lineCount={15} animated className="customSkeleton" />
      <Skeleton lineCount={15} animated className="customSkeleton" />
      <Skeleton lineCount={15} animated className="customSkeleton" />
      <Skeleton lineCount={15} animated className="customSkeleton" />
      <Skeleton lineCount={15} animated className="customSkeleton" />
    </div>
  );
};

export default Loding;
