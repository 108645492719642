import React, { useState } from "react";
import { Form, Button, AutoCenter, Stepper, DotLoading } from "antd-mobile";
import Swal from "sweetalert2";
import { ModalTop } from "../ModalComponent/ModalTop/ModalTop";
import { useAvtorization } from "util/ZustandOrdersAdd/avtorization/useAvtorization";
import css from "./Delivery.module.css";
import "./return.css";
import { requestFetch } from "Fetch/requestFetch";

export const DeliveryReturn = ({ id, product, GetOrders }) => {
  // state для загрузки
  const [isLoading, setIsLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { token } = useAvtorization();

  const ReturnProduct = (value) => {
    console.log(value);
    setIsLoading(true);
    setButtonDisabled(true);
    Swal.fire({
      title: "Вы уверены!",
      icon: "warning",
      text: ``,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "ДА",
      denyButtonText: `Нет`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        requestFetch({
          path: "vozvrat",
          value: {
            item_id: [value],
            token: token,
            order_id: id,
          },
        })
          .then(function (res) {
            if (res.data.error === 0) {
              setButtonDisabled(false);
              setIsLoading(false);
              Swal.fire({
                title: `Возврат оформлен!`,
                icon: "success",
                confirmButtonText: "OK",
              });
              GetOrders();
            } else {
              Swal.fire({
                title: "Что-то пошло не так!!!",
                icon: "warning",
                confirmButtonText: "Ок",
              });
              GetOrders();
              setIsLoading(false);
              setButtonDisabled(false);
            }
            // console.log(res);
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire({
              title: "Что-то пошло не так!!!",
              icon: "warning",
              confirmButtonText: "Ок",
            });
            setIsLoading(false);
            setButtonDisabled(false);
          });
      } else if (result.isDenied) {
        Swal.fire("Хорошо как пожелаете", "", "info");
        setIsLoading(false);
        setButtonDisabled(false);
      }
    });
  };
  //   ERR_CONNECTION_TIMED_OUT
  //   if (isLoading === false) {
  //     return (
  //       <div className={css.ReturnLoding}>
  //         <DotLoading color="currentColor" />
  //       </div>
  //     );
  //   }
  return (
    <ModalTop name={"Возврат"}>
      <AutoCenter>
        <h3 style={{ color: "#00b578", paddingTop: 15, paddingBottom: 15 }}>
          Форма возврата
        </h3>
      </AutoCenter>

      {isLoading !== false ? (
        <div className={css.ReturnLoding}>
          <p style={{ fontSize: 16 }}>ждём ответа</p>
          <DotLoading color="currentColor" />
        </div>
      ) : null}
      <div
        style={{ height: "100vh", overflowY: "scroll", padding: "0 0 45px 0" }}
      >
        <Form
          onFinish={ReturnProduct}
          footer={
            <Button
              disabled={buttonDisabled}
              block
              type="submit"
              color="primary"
              size="middle"
            >
              Возврат
            </Button>
          }
        >
          {product.map((item) => (
            <Form.Item
              key={item.id}
              initialValue={0}
              rules={[
                {
                  max: Number(item.soni),
                  min: 0,
                  type: "number",
                  message: `Количество товара меньше чем вы возвращаете!  количество: ${item.soni}`,
                },
              ]}
              name={[item.id]}
              label={
                <div style={{ textAlign: "center", width: "100%" }}>
                  {item.nomi}
                </div>
              }
              className="oreders__change"
            >
              <Stepper
                style={{
                  "--border": "1px solid #f5f5f5",
                  "--border-inner": "none",
                  "--height": "36px",
                  "--input-width": "70px",
                  "--input-background-color": "var(--adm-color-background)",
                  "--active-border": "1px solid #1677ff",
                  margin: "auto",
                }}
              />
            </Form.Item>
          ))}
        </Form>
      </div>
    </ModalTop>
  );
};
