import React, { useState, useEffect } from "react";
import { OrdersReportList } from "./OrdersReportList";
import { OrdersReportSerch } from "./OrdersReportSerch";
import { requestFetch } from "Fetch/requestFetch";

export const OrdersReport = () => {
  const [debtors, setDebtors] = useState([]);

  const GetShopt = async () => {
    requestFetch({ path: "get-shop-hisobot" })
      .then((res) => {
        if (res.status === 200) {
          setDebtors(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    GetShopt();
    // eslint-disable-next-line
  }, []);

  //   console.log(ordersReport);
  const [filtered, setFiltered] = useState([]);
  //   console.log(ordersReport);
  // функцыя для поиска данных

  useEffect(
    (_) => {
      setFiltered(debtors);
    },
    [debtors]
  );

  const Search = (val) => {
    //текущие задачи и новые отфильтрованные задачи
    let currentTodos = [],
      newList = [];
    if (val !== "") {
      //делаем копию нашего стейта
      currentTodos = debtors;
      //фильтруем стейт в поисках совпадений
      newList = currentTodos.filter((todo) => {
        // значение которое пользователь ввел и которое у нас
        // в стейте делаем строчными буквами чтобы конфликтов не было
        // мало ли пользователь ввель строчными буквами а у нас заглавные
        const lc = todo.name.toLowerCase();
        const filter = val.toLowerCase();
        // проверяем есть ли у нас этот элемент если есть возвращаем его
        return lc.includes(filter);
      });
    } else {
      // если в input ничего нету то есть пользователь стер то
      // что ввел тогда возвращаем все задачи
      newList = debtors;
    }
    setFiltered(newList);
  };

  return (
    <div>
      <div style={style}>
        <OrdersReportSerch {...{ Search }} />
      </div>
      <OrdersReportList filtered={filtered} />
    </div>
  );
};
const style = {
  position: "sticky",
  top: 0,
  zIndex: 10,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  background: "#f6f6f6",
  paddingTop: 15,
};
