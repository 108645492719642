import React from "react";
import styles from "./Global.module.css";
import { useCategory } from "util/Category/useCategory";
import { useEffect } from "react";
import useProductsHook from "util/Product/useProductsHook";
import { useAvtorization } from "util/ZustandOrdersAdd/avtorization/useAvtorization";
import useShop from "util/shop/useShop";
import useViloyat from "util/viloyat/useViloyat";
import useTuman from "util/tuman/useTuman";
import MenuBottom from "components/Menu/MenuBottom";
import { Navigate, Outlet } from "react-router-dom";
import useBalanseGoods from "util/BalansGoods/useBalanseGoods";

export const GlobalApp = () => {
  const { GetViloyat } = useViloyat();
  const { GetTuman } = useTuman();
  const { FetchDukonlar } = useShop();
  const { isAuth } = useAvtorization();
  const { GetCategory } = useCategory();
  const { GetProduct } = useProductsHook();
  const { onTransferredGoods } = useBalanseGoods();

  useEffect(() => {
    GetCategory();
    GetProduct();
    FetchDukonlar();
    GetViloyat();
    GetTuman();
    onTransferredGoods();
    //eslint-disable-next-line
  }, []);

  return isAuth ? (
    <div className={styles.app}>
      <div className={styles.body}>
        <Outlet />
      </div>
      <div className={styles.bottom}>
        <MenuBottom />
      </div>
    </div>
  ) : (
    <Navigate to={"/login"} />
  );
};
