import { Select } from "antd";
import Preloader from "components/Loading/Preloader";
import React from "react";

const ProductCategory = ({ category, handleCategory, loading }) => {
  const style = {
    position: "sticky",
    top: 10,
    zIndex: 5,
    paddingBottom: 10,
  };
  if (loading) return <Preloader />;
  return (
    <div style={style}>
      <Select
        placeholder="Категории"
        allowClear
        onSelect={handleCategory}
        style={{
          width: "100%",
          fontSize: "22px",
        }}
      >
        <Select.Option
          style={{
            fontSize: 20,
          }}
          value="0"
        >
          Все
        </Select.Option>
        {category.map((i) => (
          <Select.Option
            style={{
              fontSize: 20,
            }}
            key={i.id}
            value={i.id}
          >
            {i.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};
export default ProductCategory;
