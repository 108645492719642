import { List } from "antd";
import React from "react";
import { useEffect } from "react";
import useBalanseGoods from "util/BalansGoods/useBalanseGoods";
import css from "./Delivery.module.css";
import Refresh from "components/custom/Refresh";

export default function DeliveryBalansGoodsList() {
  const { data, loading, onFilter } = useBalanseGoods();
  useEffect(() => {
    onFilter();
    //   eslint-disable-next-line
  }, []);

  return (
    <Refresh fnc={onFilter} data={data}>
      <List
        className={css.balanseProductList}
        loading={loading}
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={
                <span style={{ margin: 0, fontSize: 14 }}>{item.nomi}</span>
              }
            />
            <p style={{ margin: 0, fontSize: 14, verticalAlign: "top" }}>
              {item.soni}
            </p>
          </List.Item>
        )}
      />
    </Refresh>
  );
}
