import React from "react";
import { Table } from "antd";
import { Button } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import Preloader from "../../Loading/Preloader";
import { numberFormat } from "util/numberFormat";
import { MdOutlineHistory } from "react-icons/md";

export const OrdersReportList = ({ filtered }) => {
  //   console.log(filtered);
  const navigate = useNavigate();
  const OrdersList = (key) => {
    navigate(`/debtors/${key}`);
  };

  const filterData = filtered.filter((item) => item.balans < -1000);

  const columns = [
    //  {
    //    title: "№",
    //    dataIndex: "id",
    //    width: 10,
    //    render: (_, record, index) => {
    //      return index + 1;
    //    },
    //  },
    {
      title: "Клиент",
      dataIndex: "name",
      width: 200,
    },
    {
      title: "Счет",
      dataIndex: "balans",
      render: (_, record) => {
        return numberFormat(record.balans);
      },
      width: 200,
    },
    {
      title: "",
      dataIndex: "operation",
      width: 50,
      render: (_, record) => {
        return (
          <div>
            <Button
              size="mini"
              color="warning"
              onClick={() => OrdersList(record.id)}
            >
              {" "}
              <MdOutlineHistory />
            </Button>
          </div>
        );
      },
    },
  ];

  if (filtered.length === 0) {
    return <Preloader />;
  }
  return (
    <div>
      <Table
        rowKey={(row) => row.id}
        bordered
        dataSource={filterData}
        columns={columns}
        rowClassName="editable-row"
        pagination={false}
        //   scroll={{
        //     x: 1124,
        //   }}
      />
    </div>
  );
};
