import React, { useEffect, useState } from "react";
import { OrdersConfirmedSearch } from "./OrdersConfirmedSearch";
import { OrderConfiredList } from "./OrderConfiredList";
import { OrdersProvider } from "../../context/OrdersProvider";
import Loding from "../../Loading/Loding";
import { AutoCenter, PullToRefresh, Toast } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";
import { requestFetch } from "Fetch/requestFetch";

export const OrdersConfirmed = () => {
  // state для получения список заказов
  const [ordersNew, setOrdersNew] = useState([]);
  // state для получения список заказов
  const [isLoading, setIsLoading] = useState(false);

  // получаем список заказов
  const GetOrders = () => {
    requestFetch({ path: "get-orders-confirm" })
      .then(function (res) {
        const OrdersData = res.data;
        OrdersData.reverse();
        setOrdersNew(OrdersData);
        setIsLoading(true);
        //   console.log("1", res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetOrders();
    // eslint-disable-next-line
  }, []);

  const [filtered, setFiltered] = useState([]);

  // функцыя для поиска данных

  useEffect(
    (_) => {
      setFiltered(ordersNew);
    },
    [ordersNew]
  );

  const Search = (val) => {
    //текущие задачи и новые отфильтрованные задачи
    let currentTodos = [],
      newList = [];
    if (val !== "") {
      //делаем копию нашего стейта
      currentTodos = ordersNew;
      //фильтруем стейт в поисках совпадений
      newList = currentTodos.filter((todo) => {
        // значение которое пользователь ввел и которое у нас
        // в стейте делаем строчными буквами чтобы конфликтов не было
        // мало ли пользователь ввель строчными буквами а у нас заглавные
        const lc = todo.dukon_name.toLowerCase();
        const filter = val.toLowerCase();
        // проверяем есть ли у нас этот элемент если есть возвращаем его
        return lc.includes(filter);
      });
    } else {
      // если в input ничего нету то есть пользователь стер то
      // что ввел тогда возвращаем все задачи
      newList = ordersNew;
    }
    setFiltered(newList);
  };

  const Styles = {
    padding: "15px 0px",
    display: "flex",
    justifyContent: "space-between",
  };

  // текст для рефреш функцыя
  const statusRecord = {
    pulling: "сильно тянуть",
    canRelease: "Отпустите для обновления",
    refreshing: "Загрузка...",
    complete: "Отлично",
  };

  // рефреш функцыя
  async function doRefresh() {
    await sleep(1000);
    if (filtered.length !== 0) {
      Toast.show({
        icon: "success",
        content: "Обнавлено!",
      });
      GetOrders();

      // setFiltered(filtered);
    } else {
      Toast.show({
        icon: "fail",
        content: "Не удалось обновить",
      });
      throw new Error("Не удалось обновить");
    }
  }

  return (
    <PullToRefresh
      onRefresh={doRefresh}
      renderText={(status) => {
        return <div>{statusRecord[status]}</div>;
      }}
    >
      <AutoCenter>
        <h3 style={{ color: "#00b578", paddingTop: 15 }}>Принятые заказы</h3>
      </AutoCenter>
      <OrdersProvider.Provider value={GetOrders}>
        <div style={Styles}>
          <OrdersConfirmedSearch {...{ Search }} />
        </div>
        {isLoading === false ? (
          <Loding />
        ) : (
          <OrderConfiredList filtered={filtered} />
        )}
      </OrdersProvider.Provider>
    </PullToRefresh>
  );
};
