import React, { useState } from "react";
import { useAvtorization } from "util/ZustandOrdersAdd/avtorization/useAvtorization";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Swal from "sweetalert2";
import { Button, DatePicker, Form, Typography } from "antd";
import { AutoCenter } from "antd-mobile";
import FilterPopup from "components/ModalComponent/Popup";
import filterFormDate from "../../util/filterFormDate";
import { requestFetch } from "Fetch/requestFetch";
dayjs.extend(customParseFormat);

const { Title } = Typography;
export default function GetFormDeliveryOrders({ setOrders, setIsLoding }) {
  const date = filterFormDate();
  const [visible2, setVisible2] = useState(false);
  const { id } = useAvtorization();
  const IntervalData = (value) => {
    setVisible2(false);
    setIsLoding(true);
    value = {
      ...value,
      sana1: value["sana1"].format("YYYY-MM-DD"),
      sana2: value["sana2"].format("YYYY-MM-DD"),
    };
    value.eltuvchi_id = id;
    requestFetch({ path: "eltuvchi-get-orderd", value: value })
      .then(function (res) {
        //   console.log(res);
        if (res.status === 200) {
          let DataReverse = res.data;
          DataReverse.reverse();
          setOrders(DataReverse);
          setIsLoding(false);
        } else if (res.status !== 200) {
          setIsLoding(false);
          Swal.fire({
            title: "Внимание!!!",
            text: " Ошибка соединения с интернетом!",
            icon: "warning",
            button: "Ок",
          });
        }
      })
      .catch(function (error) {
        setIsLoding(false);
        Swal.fire({
          title: `${error}`,
          icon: "warning",
          button: "Ок",
        });
      });
  };

  return (
    <FilterPopup
      name="Сортировка"
      visible2={visible2}
      setVisible2={setVisible2}
    >
      <AutoCenter>
        <Title level={5}>Выберите даты </Title>
      </AutoCenter>
      <Form
        name="time_related_controls"
        onFinish={IntervalData}
        layout="vertical"
        initialValues={{
          sana1: dayjs(date.dateOne),
          sana2: dayjs(date.dateTwo),
        }}
      >
        <Form.Item
          label="Начало периуда"
          name="sana1"
          rules={[
            {
              required: true,
              message: "Выберите дату",
            },
          ]}
        >
          <DatePicker
            placement="center"
            placeholder="дата"
            style={{ width: "100%" }}
            size="large"
          />
        </Form.Item>

        <Form.Item
          label="Конец периуда"
          name="sana2"
          rules={[
            {
              required: true,
              message: "Выберите дату",
            },
          ]}
        >
          <DatePicker
            placement="center"
            style={{ width: "100%" }}
            placeholder="дата"
            size="large"
          />
        </Form.Item>

        <AutoCenter>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%", marginTop: 20 }}
              size="large"
            >
              Просмотр
            </Button>
          </Form.Item>
        </AutoCenter>
      </Form>
    </FilterPopup>
  );
}
