import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, AutoCenter } from "antd-mobile";
import { OrdersProvider } from "../context/OrdersProvider";
import { FcShop } from "react-icons/fc";
import { OrdersDetailed } from "./OrdersDetailed";
import { OrderChange } from "./OrdersChange/OrderChange";
import Loding from "../Loading/Loding";
import { OrdersItemAdd } from "./OrdersChange/OrdersItemAdd";
import Preloader from "../Loading/Preloader";
import { useAvtorization } from "util/ZustandOrdersAdd/avtorization/useAvtorization";
import { numberFormat } from "util/numberFormat";
import styles from "./Orders.module.css";
import { requestFetch } from "Fetch/requestFetch";
import dateFormat from "dateformat";
export const Orders = ({ dataProduct }) => {
  const { rol, id } = useAvtorization();
  const history = useNavigate();

  const [orders, setOrders] = useState([]);

  // const onHistory = () => {
  //   history("/addorder");
  // };

  // переходим в подтвержденные заказы
  const onConfirm = () => {
    history("/confirmed");
  };

  // получаем список заказов
  const GetOrders = useCallback(() => {
    requestFetch({ path: "get-orders" })
      .then(function (res) {
        const DataReverse = res.data;
        DataReverse.reverse();
        setOrders(DataReverse);
        //   console.log("1");
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    GetOrders();
  }, [GetOrders]);

  if (!orders) {
    return <Loding />;
  }

  // eslint-disable-next-line array-callback-return
  const OrdersCard = orders?.map((i) => {
    if (
      rol === "dostavka" ||
      rol === "admin" ||
      rol === "manager" ||
      id === i.agent_id ||
      rol === "programist"
    ) {
      return (
        <Card
          title={
            <div style={{ fontWeight: "normal" }}>
              <FcShop style={{ marginRight: "4px", fontSize: 24 }} />
              <span
                style={{ fontWeight: 700, fontSize: 16, verticalAlign: "top" }}
              >
                {i.dukon_name}
              </span>
            </div>
          }
          className={styles.OrdersCardWrapper}
          key={i.id}
          bodyClassName={styles.customBody}
        >
          <div className={styles.content}>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>Дата заказа:</td>
                  <td>{dateFormat(i.sana * 1000, "dd/mm/yyyy")}</td>
                </tr>
                <tr>
                  <td>Сумма заказа:</td>
                  <td>{numberFormat(i.summa)}</td>
                </tr>
                <tr>
                  <td>Скидка:</td>
                  <td>{i.chegirma}</td>
                </tr>
                <tr>
                  <td>Агент:</td>
                  <td>{i.agent_name}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={styles.footer} onClick={(e) => e.stopPropagation()}>
            {id === i.agent_id || rol === "programist" ? (
              <>
                <div style={{ margin: "0 10px 0 0" }}>
                  <OrdersItemAdd
                    id={i.id}
                    dataProduct={dataProduct}
                    GetOrders={GetOrders}
                    dukon_name={i.dukon_name}
                  />
                </div>
                <div style={{ margin: "0 10px 0 0" }}>
                  <OrderChange
                    id={i.id}
                    products={i.products}
                    GetOrders={GetOrders}
                    dukon_name={i.dukon_name}
                  />
                </div>
              </>
            ) : null}

            <div>
              <OrdersDetailed
                id={i.id}
                date={i.date}
                agent_id={i.agent_id}
                dukon_name={i.dukon_name}
                summa={i.summa}
                agent_summa={i.agent_summa}
                agent_name={i.agent_name}
                status={i.status}
                izoh={i.izoh}
                chegirma={i.chegirma}
                products={i.products}
                finSum={i.total_skidka}
              />
            </div>
          </div>
        </Card>
      );
    }
  });
  //   if (!orders) {
  //     return <DotLoading color="primary" />;
  //   }

  return (
    <div style={{ paddingTop: 15, height: "100%" }}>
      {rol === "admin" || rol === "programist" ? (
        <div style={{ width: "100%", padding: "0 10px" }}>
          <Button
            color="success"
            size="middle"
            block
            onClick={onConfirm}
            style={{ margin: "0 0 10px 0" }}
          >
            Подтвержденные заказы{" "}
          </Button>
        </div>
      ) : null}
      <AutoCenter>
        <h2 style={{ paddingBottom: 10, color: "#00b578" }}>Новые заказы</h2>
      </AutoCenter>
      <OrdersProvider.Provider value={GetOrders}>
        {orders.length === 0 ? (
          <AutoCenter>
            <Preloader />
          </AutoCenter>
        ) : (
          <div className={styles.OrdersBlockList}>{OrdersCard}</div>
        )}
      </OrdersProvider.Provider>
    </div>
  );
};
