import React, { useState, useEffect } from "react";
import { Collapse, DotLoading, AutoCenter } from "antd-mobile";
import { WorkersAdd } from "../Workers/WorkersAdd";
import { numberFormat } from "util/numberFormat";
import { requestFetch } from "Fetch/requestFetch";
import css from "./Workers.module.css";

export const Workers = () => {
  const [renderFnc, setRenderFnc] = useState([]);

  // Запрос для таблицы рабочих
  const FetchWorkData = async () => {
    await requestFetch({ path: "get-users" })
      .then(function (res) {
        if (res.status === 200) {
          setRenderFnc(res.data);
          //  console.log("WorkerList", res.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    FetchWorkData();
    // eslint-disable-next-line
  }, []);

  if (!renderFnc) {
    return <DotLoading color="primary" />;
  }

  const DataUser = renderFnc.map((item) => (
    <Collapse.Panel key={item.id} title={item.fio}>
      <ul>
        <li>Номер(тел.): {item.pasport}</li>
        <li>Вид труда: {item.rol}</li>
        <li>Баланс: {numberFormat(item.balans)}сум.</li>
        <li>Логин: {item.login}</li>
      </ul>
    </Collapse.Panel>
  ));

  return (
    <div style={{ width: "100%", paddingTop: 15 }}>
      <div className={css.workersAddBlock}>
        <h2 style={{ color: "#00b578", marginBottom: 10 }}>Персонал</h2>
        <WorkersAdd {...{ FetchWorkData }} />
      </div>
      {renderFnc.length === 0 ? (
        <AutoCenter>
          <DotLoading color="primary" />
        </AutoCenter>
      ) : (
        <Collapse accordion>{DataUser}</Collapse>
      )}
    </div>
  );
};
