import React from "react";
import { Tag } from "antd-mobile";
import styles from "../../Orders/Orders.module.css";
export default function StatusOrder({ status }) {
  return (
    <div className={styles.DeliveryListTag}>
      {status === "berildi" ? (
        <div className={styles.container__headers__tag}>
          {" "}
          <Tag color="danger">В ожидании</Tag>
        </div>
      ) : (
        <div className={styles.container__headers__tag}>
          <Tag color="success">Принято</Tag>
        </div>
      )}
      {status !== "done" ? (
        <div className={styles.container__headers__tag}>
          <Tag color="danger">Оплата не отмечена</Tag>
        </div>
      ) : (
        <div className={styles.container__headers__tag}>
          <Tag color="warning">Принято</Tag>
        </div>
      )}
    </div>
  );
}
