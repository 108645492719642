import React, { useState } from "react";
import { ShopContext } from "./ShpContext";
import { requestFetch } from "Fetch/requestFetch";

export default function ShopState({ children }) {
  const [dukonlar, setDukonlar] = useState([]);

  const FetchDukonlar = async () => {
    await requestFetch({ path: "shops-get" })
      .then(function (res) {
        if (res.status === 200) {
          setDukonlar(res.data);
          //  console.log("dukonlar", res.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <ShopContext.Provider value={{ FetchDukonlar, dukonlar }}>
      {children}
    </ShopContext.Provider>
  );
}
