import React from "react";
import { Input } from "antd";
export const OrdersConfirmedSearch = (props) => {
  return (
    <Input
      style={{ margin: "0px 10px" }}
      onChange={({ target: { value } }) => props.Search(value)}
      type="text"
      placeholder="Поиск..."
    />
  );
};
