import { Input } from "antd";
import React from "react";

// import { Input } from "antd-mobile";

export const Search = (props) => {
  return (
    <Input
      onChange={({ target: { value } }) => props.Poisk(value)}
      type="text"
      placeholder="Поиск..."
    />
  );
};
