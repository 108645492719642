import React, { useState } from "react";
import { OrdersFilterForm } from "./OrdersFilterForm";
import { OrdersDataTable } from "./OrdersDataTable";
import Loding from "../../Loading/Loding";
import { AutoCenter } from "antd-mobile";

export const OrdersFilter = () => {
  // получаем данные формы и передаем таблице
  const [interval, setInterval] = useState([]);
  // loding
  const [isLoding, setIsLoding] = useState(false);
  // даты
  const [dateOne, setDateOne] = useState("");
  const [date2, setDate2] = useState("");

  return (
    <div style={Style.WrapperBlock}>
      <AutoCenter>
        <h4 style={{ color: "#00b578", fontSize: 22 }}>Товары для доставки</h4>
      </AutoCenter>
      <div style={Style.filertBlock}>
        <OrdersFilterForm
          setInterval={setInterval}
          setIsLoding={setIsLoding}
          setDateOne={setDateOne}
          setDate2={setDate2}
        />
      </div>
      {isLoding === false ? (
        <Loding />
      ) : (
        <OrdersDataTable interval={interval} dateOne={dateOne} date2={date2} />
      )}
    </div>
  );
};
const Style = {
  WrapperBlock: {
    padding: "10px 10px 0 10px",
  },
  filertBlock: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 10px",
  },
};
