import { requestFetch } from "Fetch/requestFetch";
import { BalanseGoods } from "components/context/StoreProvider";
import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import { useAvtorization } from "util/ZustandOrdersAdd/avtorization/useAvtorization";

export default function BalansGoodsState({ children }) {
  const { id } = useAvtorization();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [transferData, setTransferData] = useState([]);
  function onFilter() {
    setLoading(true);
    requestFetch({ path: "get-deliver-product", value: { eltuvchi_id: id } })
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const onTransferredGoods = useCallback(() => {
    setLoading(true);
    requestFetch({ path: "getdtdp", value: { eltuvchi_id: id } })
      .then((res) => {
        if (res.status === 200) {
          setTransferData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  function onTransferConfirm(or_id) {
    setLoading(true);
    Swal.fire({
      title: "Хотитет принят продукции?!",
      icon: "warning",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "ДА",
      denyButtonText: `Нет`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        requestFetch({
          path: "eltuvchi-product-confirm",
          value: { eltuvchi_id: id, order_id: or_id },
        })
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              Swal.fire({
                title: `Ушпешно приняты!`,
                text: "Проверьте остатки товаров",
                icon: "success",
                confirmButtonText: "Спасибо",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              title: "Oopsss..",
              text: "Ошибки на стороне сервера",
              icon: "error",
              confirmButtonText: "Спасибо",
            });
          })
          .finally((err) => {
            setLoading(false);
          });
      } else if (result.isDenied) {
        setLoading(false);
        Swal.fire("Хорошо как пожелаете", "", "info");
      }
    });
  }

  return (
    <BalanseGoods.Provider
      value={{
        onFilter,
        data,
        loading,
        onTransferredGoods,
        transferData,
        onTransferConfirm,
      }}
    >
      {children}
    </BalanseGoods.Provider>
  );
}
