import { TabBar } from "antd-mobile";
import { TabsProvider } from "components/context/StoreProvider";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "util/ZustandOrdersAdd/avtorization/userAvtorization";

export default function MenuBottom() {
  const { user } = useAuth();
  const State = useContext(TabsProvider);
  const exitUser = useAuth((state) => state.exitUser);
  // tab bar
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const setRouteActive = (value) => {
    if (value === "/login") {
      localStorage.clear();
      exitUser();
      navigate("/login");
    }
    navigate(value);
  };

  const menuItem =
    user.rol === "agent"
      ? State.agentTabs
      : user.rol === "dostavka"
      ? State.deliverTabs
      : State.adminTabs;
  return (
    <TabBar activeKey={pathname} onChange={setRouteActive}>
      {menuItem.map((item) => (
        <TabBar.Item
          key={item.key}
          icon={item.icon}
          //   title={item.title}
          //  style={{ color: "#000" }}
        />
      ))}
    </TabBar>
  );
}
