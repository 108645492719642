import React from "react";
import { Button, AutoCenter } from "antd-mobile";
import { Select, Form, Input } from "antd";
import { PopupModal } from "../../ModalComponent/ModalPopup/PopupModal";
import { OrdersItemPost } from "./OrdersItemPost";
import { OrdersItemTable } from "./OrdersItemTable";
import { AddCircleOutline } from "antd-mobile-icons";
import useProductsHook from "util/Product/useProductsHook";
import { useUpdateOpders } from "util/ZustandOrdersAdd/OrdersAddNewItem/OrdersAddNewItemState";
import style from "../Orders.module.css";

export const OrdersItemAdd = ({ id, dukon_name, GetOrders }) => {
  const { addOrderItem, orderItem } = useUpdateOpders();

  const { product } = useProductsHook();
  const [form] = Form.useForm();

  // получаем данные формы
  const AddOrders = (value) => {
    const item = product.find((i) => i.id === value.product_id);
    const mewItem = {
      name: item.name,
      product_id: item.id,
      price: item.price,
      soni: value.soni,
    };
    addOrderItem(mewItem);
    form.resetFields();
  };

  return (
    <PopupModal name={<AddCircleOutline style={{ fontSize: 18 }} />}>
      <div className={style.OrderItemAddContainer}>
        <AutoCenter>
          <h3>{dukon_name}</h3>
        </AutoCenter>
        <Form form={form} layout="vertical" onFinish={AddOrders}>
          <Form.Item
            name="product_id"
            label="Выберите наименование"
            rules={[{ required: true, message: "Выберите наименование" }]}
          >
            {/* <Select>{options}</Select> */}
            <Select
              showSearch
              style={{
                width: "100%",
              }}
              // placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={product?.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="soni"
            label="Введите каличество!"
            rules={[{ required: true, message: "Введите каличество!" }]}
          >
            <Input type="number" placeholder="" autoComplete="off" />
          </Form.Item>

          <Form.Item>
            <Button
              type="submit"
              block
              color="danger"
              size="large"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              Добавить
            </Button>
          </Form.Item>
        </Form>

        <div className={style.OrdersItemPost}>
          {orderItem.length && <OrdersItemPost id={id} GetOrders={GetOrders} />}
        </div>

        <div className={style.OrdersTableBlock}>
          <OrdersItemTable />
        </div>
      </div>
    </PopupModal>
  );
};
