import React from "react";
import { useState } from "react";
import { TumanContext } from "./TumanContext";
import { requestFetch } from "Fetch/requestFetch";

export default function TumanState({ children }) {
  const [tuman, setTuman] = useState([]);
  const GetTuman = async () => {
    await requestFetch({ path: "districts" })
      .then((res) => {
        if (res.status === 200) setTuman(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <TumanContext.Provider value={{ GetTuman, tuman }}>
      {children}
    </TumanContext.Provider>
  );
}
