import React, { useState, useEffect } from "react";
import { DeliveryList } from "./DeliveryList";
import { RepaymentOrder } from "./RepaymentOrder";
import { Search } from "./Search";
import { useAvtorization } from "util/ZustandOrdersAdd/avtorization/useAvtorization";
import useShop from "../../util/shop/useShop";
import css from "./Delivery.module.css";
import GetFormDeliveryOrders from "./GetFormDeliveryOrders";
import Swal from "sweetalert2";
import filterFormDate from "../../util/filterFormDate";
import { Grid } from "antd-mobile";
import { requestFetch } from "Fetch/requestFetch";

export const Delivery = () => {
  const date = filterFormDate();
  const { id } = useAvtorization();
  const { dukonlar } = useShop();

  const [isLoding, setIsLoding] = useState(false);
  // state дял получения данных заказа
  const [orders, setOrders] = useState([]);
  // запрос данных
  const IntervalDataEffect = (value) => {
    setIsLoding(true);
    value = {
      ...value,
      sana1: date.dateOne,
      sana2: date.dateTwo,
    };
    value.eltuvchi_id = id;
    requestFetch({ path: "eltuvchi-get-orderd", value })
      .then(function (res) {
        if (res.status === 200) {
          let DataReverse = res.data;
          DataReverse.reverse();
          setOrders(DataReverse);
        } else if (res.status !== 200) {
          Swal.fire({
            title: "Внимание!!!",
            text: " Ошибка соединения с интернетом!",
            icon: "warning",
            button: "Ок",
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: `${error}`,
          icon: "warning",
          button: "Ок",
        });
      })
      .finally(() => {
        setIsLoding(false);
      });
  };

  useEffect(() => {
    IntervalDataEffect();
    // eslint-disable-next-line
  }, []);
  // функцыя для поиска данных
  const [filtered, setFiltered] = useState([]);

  useEffect(
    (_) => {
      setFiltered(orders);
    },
    [orders]
  );

  const Poisk = (val) => {
    let currentTodos = [],
      newList = [];
    if (val !== "") {
      currentTodos = orders;
      newList = currentTodos.filter((todo) => {
        const lc = todo.dukon_name?.toLowerCase();
        const filter = val.toLowerCase();
        return lc?.includes(filter);
      });
    } else {
      newList = orders;
    }
    setFiltered(newList);
  };

  return (
    <div>
      <div className={css.DeliveryHeaderBlock}>
        <Grid columns={2} gap={8}>
          <Grid.Item>
            <GetFormDeliveryOrders
              setIsLoding={setIsLoding}
              setOrders={setOrders}
            />
          </Grid.Item>
          <Grid.Item>
            <RepaymentOrder
              dukonlar={dukonlar}
              GetOrders={IntervalDataEffect}
            />
          </Grid.Item>
        </Grid>
        <Search {...{ Poisk }} />
      </div>
      <div>
        <DeliveryList
          orders={filtered}
          GetOrders={IntervalDataEffect}
          isLoding={isLoding}
        />
      </div>
    </div>
  );
};
