import React from "react";
import { Table, Typography } from "antd";
import Loding from "../../Loading/Loding";
const { Title } = Typography;

const columns = [
  {
    title: "Наименование",
    dataIndex: "nomi",
  },
  {
    title: "шт.",
    dataIndex: "soni",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.soni - b.soni,
  },
];

const onChange = (pagination, filters, sorter, extra) => {
  //   console.log("params", pagination, filters, sorter, extra);
};

export const OrdersDataTable = ({ interval, dateOne, date2 }) => {
  if (interval.length === 0) {
    return <Loding />;
  }
  return (
    <>
      <div>
        <div style={{ padding: "0 15px" }}>
          <Title level={4}>
            {dateOne} - {date2}
          </Title>
        </div>
        <Table
          rowKey={(row) => row.pr_id}
          columns={columns}
          dataSource={interval}
          onChange={onChange}
          pagination={false}
          style={{ fontSize: 20 }}
        />
      </div>
    </>
  );
};
