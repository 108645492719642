import React, { useState } from "react";
import { Popup, Button } from "antd-mobile";
import { CloseOutline } from "antd-mobile-icons";
export const PopupModal = ({ name, children }) => {
  const [visible, setVisible] = useState(false);

  const Style = {
    display: "flex",
    justifyContent: "flex-end",
    padding: "5px 10px 0 0",
  };

  return (
    <>
      <Button
        block
        color="success"
        size="small"
        onClick={() => {
          setVisible(true);
        }}
        //   style={{ marginBottom: 10 }}
      >
        {name}
      </Button>

      <Popup
        position="top"
        visible={visible}
        //   showCloseButton
        onMaskClick={() => {
          setVisible(false);
        }}
        bodyStyle={{ height: "100vh" }}
      >
        <div style={Style}>
          <Button
            color="danger"
            size="mini"
            onClick={() => {
              setVisible(false);
            }}
            style={{ color: "#fff" }}
          >
            <CloseOutline />
          </Button>
        </div>
        <div> {children}</div>
      </Popup>
    </>
  );
};
