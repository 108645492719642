import React, { useState } from "react";
import { Popup, Button } from "antd-mobile";
import { CloseOutline } from "antd-mobile-icons";
import "./ModalTop.css";
export const ModalTop = ({ name, children }) => {
  const [visible2, setVisible2] = useState(false);

  return (
    <>
      <Button
        block
        size="middle"
        color="success"
        onClick={() => {
          setVisible2(true);
        }}
        //   className="top__modal-btn"
      >
        {name}
      </Button>
      <Popup
        visible={visible2}
        onMaskClick={() => {
          setVisible2(false);
        }}
        position="top"
        bodyStyle={{ height: "100vh" }}
        //   className="top__modal-wrapper"
      >
        <div className="top__modal-btn-fixed">
          <Button
            onClick={() => {
              setVisible2(false);
            }}
            className="top__modal-btn"
            style={{ color: "#1677ff" }}
          >
            <CloseOutline />
          </Button>
        </div>
        <div> {children}</div>
      </Popup>
    </>
  );
};
