import { create } from "zustand";
import { Toast } from "antd-mobile";
import { persist } from "zustand/middleware";

export const useUpdateOpders = create(
  persist(
    (set) => ({
      orderItem: [],
      addOrderItem: (item) =>
        set((state) => {
          const ord = state.orderItem;
          const findProduct = ord.find((p) => p.product_id === item.product_id);
          if (findProduct) {
            findProduct.soni = Number(findProduct.soni) + Number(item.soni);
            if (findProduct.soni < 0) {
              Toast.show({
                duration: 3000,
                position: "top",
                content: "Наименование удалено из Прайс листа",
              });
              findProduct.soni = 0;
            } else if (item.soni < 0) {
              Toast.show({
                duration: 3000,
                position: "top",
                content: `${item.soni + "шт минусована"}`,
              });
            } else {
              Toast.show({
                duration: 3000,
                position: "top",
                content: `${item.soni + "\xa0шт добавлено"}`,
              });
            }
            const newItem = ord.filter((i) => i.soni !== 0);
            return { orderItem: newItem };
          } else {
            Toast.show({ position: "top", content: "Добавлено" });
            return { orderItem: [...state.orderItem, item] };
          }
        }),
      removeItem: (key) =>
        set((state) => {
          const newOrders = state.orderItem.filter((i) => i.product_id !== key);
          Toast.show({
            duration: 3000,
            position: "top",
            content: "Наименование удалено из Прайс листа",
          });
          return { orderItem: newOrders };
        }),
      deleteOrder: (key) =>
        set((state) => {
          return { orderItem: [] };
        }),
    }),
    { name: "updateOrderItem" }
  )
);
