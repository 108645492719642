import React from "react";
import "./loding.css";
const Preloader = () => {
  return (
    <div className="load">
      <hr />
      <hr />
      <hr />
      <hr />
    </div>
  );
};

export default Preloader;
