import React, { useState } from "react";
import { ProductContext } from "./GetProductContext";
import { Toast } from "antd-mobile";
import { requestFetch } from "Fetch/requestFetch";

export default function GetProductState({ children }) {
  const [product, setProduct] = useState([]);
  const [productLoad, setProductLoad] = useState(false);

  // получаем список продукции
  const GetProduct = () => {
    setProductLoad(true);
    requestFetch({ path: "products" })
      .then(function (res) {
        if (res.status === 200) {
          setProductLoad(false);
          setProduct(res.data);
        } else
          throw new Error(
            Toast.show({
              icon: "fail",
              content: "Что-то пошло не так при загрузке продукции",
            })
          );
      })
      .catch(function (error) {
        console.log(error);
        // Toast.show({
        //   duration: 3000,
        //   icon: "fail",
        //   content: `Список продукции не загрузилось!!!  ${error.message}`,
        // });
      });
  };
  return (
    <ProductContext.Provider value={{ GetProduct, productLoad, product }}>
      {children}
    </ProductContext.Provider>
  );
}
