import { Button, Popup, Space } from "antd-mobile";
import React from "react";
import { useState } from "react";
import useBroadCats from "util/Broadcast/useBroadCats";
import { useAvtorization } from "util/ZustandOrdersAdd/avtorization/useAvtorization";

export default function DeliveryBroadcast({ order_id, GetOrders, user }) {
  const { onOrdersTransfer } = useBroadCats();
  const { id } = useAvtorization();
  const [visible1, setVisible1] = useState(false);

  const users = user.map((item) =>
    item.rol === "dostavka" && item.id !== 1 && item.id !== id ? (
      <Button
        onClick={() => {
          onOrdersTransfer(item.id, order_id, GetOrders);
          setVisible1(false);
        }}
        style={{ width: "100%" }}
        color="success"
        key={item.id}
      >
        {item.fio}
      </Button>
    ) : null
  );

  return (
    <div>
      <Button
        size="mini"
        onClick={() => {
          setVisible1(true);
        }}
        color="warning"
      >
        Передать заказ
      </Button>
      <Popup
        visible={visible1}
        onMaskClick={() => {
          setVisible1(false);
        }}
        onClose={() => {
          setVisible1(false);
        }}
      >
        <div style={{ height: "40vh", overflowY: "scroll", padding: "20px" }}>
          <Space direction="vertical" block>
            {users}
          </Space>
        </div>
      </Popup>
    </div>
  );
}
