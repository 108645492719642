import React, { useState, useEffect } from "react";
import { Button, DatePicker, Form, Typography } from "antd";
import Swal from "sweetalert2";
import { PopupModal } from "../../ModalComponent/ModalPopup/PopupModal";
import { AutoCenter } from "antd-mobile";
import { useAvtorization } from "util/ZustandOrdersAdd/avtorization/useAvtorization";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { requestFetch } from "Fetch/requestFetch";
import filterFormDate from "util/filterFormDate";
dayjs.extend(customParseFormat);

const { Title } = Typography;

export const OrdersFilterForm = ({
  setInterval,
  setIsLoding,
  setDateOne,
  setDate2,
}) => {
  const { dateOne, dateTwo } = filterFormDate();

  const { id } = useAvtorization();

  // запрос данных
  const IntervalData = (value) => {
    value = {
      ...value,
      sana1: value["sana1"].format("YYYY-MM-DD"),
      sana2: value["sana2"].format("YYYY-MM-DD"),
    };
    setDateOne(value.sana1);
    setDate2(value.sana2);
    value.eltuvchi_id = id;
    console.log("value", value);

    requestFetch({ path: "get-hisobot-eltuvchi", value })
      .then(function (res) {
        //   console.log(res);
        if (res.status === 200) {
          setInterval(res.data);
          setIsLoding(true);
          console.log("interval", res.data);
        } else if (res.status !== 200) {
          Swal.fire({
            title: "Внимание!!!",
            text: " Ошибка соединения с интернетом!",
            icon: "warning",
            button: "Ок",
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: `${error}`,
          icon: "warning",
          button: "Ок",
        });
      });
  };

  // запрос данных
  const IntervalDataEffect = (value) => {
    value = {
      ...value,
      sana1: dateOne,
      sana2: dateTwo,
    };
    setDateOne(value.sana1);
    setDate2(value.sana2);
    value.eltuvchi_id = id;
    requestFetch({ path: "get-hisobot-eltuvchi", value: value })
      .then(function (res) {
        if (res.status === 200) {
          setInterval(res.data);
          setIsLoding(true);
          //  console.log("interval", res.data);
        } else if (res.status !== 200) {
          Swal.fire({
            title: "Внимание!!!",
            text: " Ошибка соединения с интернетом!",
            icon: "warning",
            button: "Ок",
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: `${error}`,
          icon: "warning",
          button: "Ок",
        });
      });
  };

  useEffect(() => {
    IntervalDataEffect();
    // eslint-disable-next-line
  }, []);

  const [placement] = useState("bottomRight");

  return (
    <PopupModal name="Сортировка">
      <AutoCenter>
        <Title level={5}>Выберите даты </Title>
      </AutoCenter>
      <Form
        name="time_related_controls"
        onFinish={IntervalData}
        layout="vertical"
      >
        <Form.Item
          label="Начало периуда"
          name="sana1"
          rules={[
            {
              required: true,
              message: "Выберите дату",
            },
          ]}
        >
          <DatePicker
            placement={placement}
            placeholder="дата"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          label="Конец периуда"
          name="sana2"
          rules={[
            {
              required: true,
              message: "Выберите дату",
            },
          ]}
        >
          <DatePicker
            placement={placement}
            style={{ width: "100%" }}
            placeholder="дата"
          />
        </Form.Item>

        <AutoCenter>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginLeft: 5, padding: 0, width: 100, marginTop: 20 }}
            >
              Просмотр
            </Button>
          </Form.Item>
        </AutoCenter>
      </Form>
    </PopupModal>
  );
};
